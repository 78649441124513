// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from "lodash";
import { useEffect, useState } from "react";

import { Flex, Input, TabContent, Textarea, makeToast } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";

import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { JiraIssueRequest, useCreateIssue } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import { StringParam, useQueryParam } from "use-query-params";
import {
  AutomationPriorityOptions,
  AutomationRequestType,
  AutomationRequestTypeOptions,
  BugPriorityOptions,
  DescribeRequest,
  GPTChatVersionOptions,
  GrowthBugProducts,
  GrowthProductsOptions,
  ProductOptions,
  Products,
  RequestCategory,
  RequestCategoryOptions,
  TestProp,
  WebProductsOptions,
  WorkImpactOptions,
} from "./data";
import { getJiraPriorityId } from "./utils/jiraUtils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GrowthEngineering = (props: TestProp) => {
  const userInfo = getUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(1);
  const [describeRequest] = useQueryParam("describe_request", StringParam);
  const [requestTitle, setRequestTitle] = useState<string>("");
  const [requestCategory, setRequestCategory] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [workImpact, setWorkImpact] = useState<string>("");
  const [keyStakeholders, setKeyStakeholders] = useState<string>("");
  const [acceptanceCriteria, setAcceptanceCriteria] = useState<string>("");
  const [automationRequestType, setAutomationRequestType] = useState<string>("");
  const [requestFeedbackSummary, setRequestFeedbackSummary] = useState<string>("");
  const [gptChatVersion, setGptChatVersion] = useState<string>("");
  const [requestSummary, setRequestSummary] = useState<string>("");
  const [automationPriority, setAutomationPriority] = useState<string>("");
  const [bugSummary, setBugSummary] = useState<string>("");
  const [acceptanceCriteriaBug, setAcceptanceCriteriaBug] = useState<string>("");
  const [bugPriority, setBugPriority] = useState<string>("");
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();

  const onSubmit = async () => {
    if (createJiraIssueRes.loading) {
      return; // Prevent duplicate submissions
    }

    let issueData = `
          *Reporter*: ${userInfo?.email}
          *Request Type*: ${describeRequest}`;
    issueData += `
          *Title*: ${requestTitle}`;
    if (isProductRequestFeedback) {
      issueData += `
            *Product*: ${product}`;
      if (product === Products.GPT) {
        issueData += `
          *Chat Version*: ${gptChatVersion}`;
      }
      issueData += `
            *Request/Feedback Summary*: ${requestFeedbackSummary}
            *Key Stakeholders*: ${keyStakeholders}
            *Acceptance Criteria*: ${acceptanceCriteria}
          `;
      if (requestCategory === RequestCategory.WebEngineering) {
        issueData += `*Priority*: ${bugPriority}
        `;
      } else {
        issueData += `*Work Impact*: ${workImpact}
        `;
      }
    }

    if (isWorkflowAutomation) {
      issueData += `
            *Request Summary*: ${requestSummary}
            *Work Impact*: ${workImpact}
            *Priority*: ${automationPriority}
            *Key Stakeholders*: ${keyStakeholders}
          `;
    }
    if (isBugReport) {
      issueData += `
        *Product*: ${product}`;
      if (product === Products.GPT) {
        issueData += `
          *Chat Version*: ${gptChatVersion}`;
      }
      issueData += `
        *Acceptance Criteria*: ${acceptanceCriteriaBug}
        *Bug Summary*: ${bugSummary}
        *Bug Priority*: ${bugPriority}
      `;
    }
    const jiraConfig = getJiraConfig("Growth Engineering", {
      describeRequest,
      requestCategory,
    });
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!");
      return; // Required
    }

    const priorityId = getJiraPriorityId(describeRequest, automationPriority, bugPriority, requestCategory);

    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: requestTitle,
        issuetype: {
          name: "Task",
        },
        // eslint-disable-next-line camelcase
        customfield_10000: jiraConfig.epicLink,
        labels: jiraConfig.labels,
        priority: {
          id: priorityId,
        },
      },
    };
    if (isBugReport) {
      jiraIssueRequest.fields.issuetype.name = "Bug";
    } else {
      jiraIssueRequest.fields.issuetype.name = "Task";
    }

    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      // If result is null and there's an error state, display the toast notification
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  const isBugReport = describeRequest == DescribeRequest.BugReport;
  const isProductRequestFeedback = describeRequest == DescribeRequest.ProductRequestFeedback;
  const isWorkflowAutomation = describeRequest == DescribeRequest.WorkflowAutomation;

  const bugReportCriteria = !product || !acceptanceCriteriaBug || !bugPriority || !bugSummary;
  const productRequestFeedbackCriteria =
    !requestCategory ||
    !product ||
    !requestFeedbackSummary ||
    !acceptanceCriteria ||
    (requestCategory !== RequestCategory.WebEngineering && !workImpact) ||
    (requestCategory === RequestCategory.WebEngineering && !bugPriority);
  const workflowAutomationCriteria =
    !workImpact || !automationRequestType || !requestSummary || !automationPriority;

  const disabledSubmitButton =
    !describeRequest ||
    !requestTitle ||
    (isBugReport && bugReportCriteria) ||
    (isProductRequestFeedback && productRequestFeedbackCriteria) ||
    (isProductRequestFeedback &&
      requestCategory === RequestCategory.GrowthEngineering &&
      !GrowthProductsOptions.find((item) => item.value === product)) ||
    (isProductRequestFeedback &&
      requestCategory === RequestCategory.WebEngineering &&
      !WebProductsOptions.find((item) => item.value === product)) ||
    (isWorkflowAutomation &&
      automationRequestType !== AutomationRequestType.Slidebot &&
      workflowAutomationCriteria) ||
    createJiraIssueRes.loading;

  useEffect(() => {
    if (isProductRequestFeedback) {
      setProduct(""); // Clear product selection
    }
  }, [requestCategory]);

  return (
    <>
      <NavigateBackButton />
      <RequestForm>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>{describeRequest}</FormHeading>
              <TabContent active={activeTab === 1}>
                <FormDetails>
                  {describeRequest === DescribeRequest.ProductRequestFeedback && (
                    <InputLabel label="Request Category" htmlFor="request-category" formatLabel={requiredLabel}>
                      <SubText>
                        Please choose Growth Engineering Request for internal applications (e.g. Masquerade,
                        GPT-At-Braze). Choose Web Engineering Requests for requests related to Braze.com, Braze
                        Learning and Docs.
                      </SubText>
                      <Select
                        inputId="request-category"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        fluid
                        options={RequestCategoryOptions}
                        onChange={(selectedOption) => {
                          setRequestCategory(selectedOption?.value || "");
                        }}
                        value={RequestCategoryOptions.find((o) => o.value === requestCategory)}
                      />
                    </InputLabel>
                  )}

                  {(requestCategory === RequestCategory.GrowthEngineering ||
                    requestCategory === RequestCategory.WebEngineering) && (
                    <>
                      <InputLabel label="Select a Product" htmlFor="select-product" formatLabel={requiredLabel}>
                        <SubText>Please choose the product that is relevant to your request or feedback.</SubText>
                        <Select
                          inputId="select-product"
                          isDisabled={false}
                          isClearable
                          isSearchable
                          fluid
                          options={
                            requestCategory === RequestCategory.GrowthEngineering
                              ? GrowthProductsOptions
                              : WebProductsOptions
                          }
                          onChange={(selectedOption) => {
                            setProduct(selectedOption?.value || "");
                          }}
                          value={product ? ProductOptions.find((o) => o.value === product) : null}
                        />
                      </InputLabel>
                      {product === Products.GPT && (
                        <InputLabel label="Chat Version" htmlFor="chat-version">
                          <SubText>Please select the Chat version.</SubText>
                          <Select
                            inputId="chat-version"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={GPTChatVersionOptions}
                            onChange={(selectedOption) => {
                              setGptChatVersion(selectedOption?.value || "");
                            }}
                            value={GPTChatVersionOptions.find((o) => o.value === gptChatVersion)}
                          />
                        </InputLabel>
                      )}
                      <InputLabel label="Title" htmlFor="request-title" formatLabel={requiredLabel}>
                        <SubText>Enter a brief title for your request.</SubText>
                        <Input
                          id="request-title"
                          required
                          maxLength={200}
                          fullWidth
                          value={requestTitle}
                          onChange={(e) => setRequestTitle(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Request/Feedback Summary"
                        htmlFor="request-feedback-summary"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please provide a brief summary of your request or feedback. This should underline your
                          desired product functionalities, the reasons behind its necessity, and any specific
                          features it should encompass. or highlight the primary problems you're encountering.
                        </SubText>
                        <Textarea
                          id="request-feedback-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={requestFeedbackSummary}
                          onChange={(e) => setRequestFeedbackSummary(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Acceptance Criteria"
                        htmlFor="acceptance-criteria"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          List the specific conditions that the product must meet for your request to be accepted.
                          These could include functional requirements, performance standards, or other criteria that
                          will determine whether the product meets your needs.
                        </SubText>
                        <Textarea
                          id="acceptance-criteria"
                          vSize="lg"
                          fullWidth
                          value={acceptanceCriteria}
                          onChange={(e) => setAcceptanceCriteria(e.target.value)}
                        />
                      </InputLabel>
                      {requestCategory !== RequestCategory.WebEngineering && (
                        <InputLabel label="Work Impact" htmlFor="product-work-impact" formatLabel={requiredLabel}>
                          <SubText>
                            Select the level of impact this request will have on your work or your team's work.
                          </SubText>
                          <Select
                            inputId="product-work-impact"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={WorkImpactOptions}
                            onChange={(selectedOption) => {
                              setWorkImpact(selectedOption?.value || "");
                            }}
                            value={WorkImpactOptions.find((o) => o.value === workImpact)}
                          />
                        </InputLabel>
                      )}
                      <InputLabel label="Priority" htmlFor="web-priority" formatLabel={requiredLabel}>
                        <SubText>
                          Indicate the priority of your request. This helps us prioritize tasks effectively.
                        </SubText>
                        <Select
                          inputId="web-priority"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={BugPriorityOptions} // Reusing the BugPriorityOptions
                          onChange={(selectedOption) => {
                            setBugPriority(selectedOption?.value || ""); // Use bugPriority
                          }}
                          value={BugPriorityOptions.find((o) => o.value === bugPriority)}
                        />
                      </InputLabel>
                      <InputLabel label="Key Stakeholders" htmlFor="key-stakeholders">
                        <SubText>
                          List the people who will be most affected by this request or who need to be involved in
                          the decision-making process. This could include team members, managers, or other
                          departments.
                        </SubText>
                        <Input
                          id="key-stakeholders"
                          fullWidth
                          value={keyStakeholders}
                          onChange={(e) => setKeyStakeholders(e.target.value)}
                        />
                      </InputLabel>
                    </>
                  )}
                  {isWorkflowAutomation && (
                    <>
                      <InputLabel label="Title" htmlFor="request-title" formatLabel={requiredLabel}>
                        <SubText>Enter a brief title for your request.</SubText>
                        <Input
                          id="request-title"
                          required
                          maxLength={200}
                          fullWidth
                          value={requestTitle}
                          onChange={(e) => setRequestTitle(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Request Type"
                        htmlFor="automation-request-type"
                        formatLabel={requiredLabel}
                      >
                        <SubText>Please select the type of request you're submitting.</SubText>
                        <Select
                          inputId="automation-request-type"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={AutomationRequestTypeOptions}
                          onChange={(selectedOption) => {
                            setAutomationRequestType(selectedOption?.value || "");
                          }}
                          value={AutomationRequestTypeOptions.find((o) => o.value === automationRequestType)}
                        />
                      </InputLabel>
                      <InputLabel label="Request Summary" htmlFor="request-summary" formatLabel={requiredLabel}>
                        <SubText>
                          Provide a brief summary of your request. This should include the main points of what
                          you're asking for and why it's important.
                        </SubText>
                        <Textarea
                          id="request-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={requestSummary}
                          onChange={(e) => setRequestSummary(e.target.value)}
                        />
                      </InputLabel>

                      <InputLabel label="Work Impact" htmlFor="automation-work-impact" formatLabel={requiredLabel}>
                        <SubText>
                          Select the level of impact this request will have on your work or your team's work.
                        </SubText>
                        <Select
                          inputId="automation-work-impact"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={WorkImpactOptions}
                          onChange={(selectedOption) => {
                            setWorkImpact(selectedOption?.value || "");
                          }}
                          value={WorkImpactOptions.find((o) => o.value === workImpact)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Priority within your Department"
                        htmlFor="automation-priority"
                        formatLabel={requiredLabel}
                      >
                        <SubText>
                          Please indicate how urgent this request is within your department. This will help us
                          prioritize our work and respond to the most urgent needs first.
                        </SubText>
                        <Select
                          inputId="automation-priority"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={AutomationPriorityOptions}
                          onChange={(selectedOption) => {
                            setAutomationPriority(selectedOption?.value || "");
                          }}
                          value={AutomationPriorityOptions.find((o) => o.value === automationPriority)}
                        />
                      </InputLabel>
                      <InputLabel label="Key Stakeholders" htmlFor="key-stakeholders">
                        <SubText>
                          List the people who will be most affected by this request or who need to be involved in
                          the decision-making process. This could include team members, managers, or other
                          departments.
                        </SubText>
                        <Input
                          id="key-stakeholders"
                          fullWidth
                          value={keyStakeholders}
                          onChange={(e) => setKeyStakeholders(e.target.value)}
                        />
                      </InputLabel>
                    </>
                  )}
                  {isBugReport && (
                    <Flex direction="column">
                      <InputLabel label="Select a Product" htmlFor="select-product" formatLabel={requiredLabel}>
                        <SubText>Please choose the product that is relevant to your request or feedback.</SubText>
                        <Select
                          inputId="select-product"
                          isClearable
                          isDisabled={false}
                          isSearchable
                          fluid
                          options={GrowthBugProducts}
                          onChange={(selectedOption) => {
                            setProduct(selectedOption?.value || "");
                            selectedOption?.value === Products.BrazeWeb
                              ? setRequestCategory(Products.BrazeWeb)
                              : setRequestCategory("General");
                          }}
                          value={ProductOptions.find((o) => o.value === product)}
                        />
                      </InputLabel>
                      {product === Products.GPT && (
                        <InputLabel label="Chat Version" htmlFor="chat-version">
                          <SubText>Please select the Chat version.</SubText>
                          <Select
                            inputId="chat-version"
                            isClearable
                            isDisabled={false}
                            isSearchable
                            fluid
                            options={GPTChatVersionOptions}
                            onChange={(selectedOption) => {
                              setGptChatVersion(selectedOption?.value || "");
                            }}
                            value={GPTChatVersionOptions.find((o) => o.value === gptChatVersion)}
                          />
                        </InputLabel>
                      )}
                      <InputLabel label="Title" htmlFor="request-title" formatLabel={requiredLabel}>
                        <SubText>Enter a brief title for your request.</SubText>
                        <Input
                          id="request-title"
                          required
                          maxLength={200}
                          fullWidth
                          value={requestTitle}
                          onChange={(e) => setRequestTitle(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel label="Bug Summary" htmlFor="bug-summary" formatLabel={requiredLabel}>
                        <SubText>Please include detailed summary and steps to reproduce the bug.</SubText>
                        <Textarea
                          id="bug-summary"
                          vSize="xl"
                          fullWidth
                          maxLength={500}
                          value={bugSummary}
                          onChange={(e) => setBugSummary(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel
                        label="Acceptance Criteria"
                        htmlFor="acceptance-criteria-bug"
                        formatLabel={requiredLabel}
                      >
                        <SubText>Specify the conditions that must be met for your request to be accepted.</SubText>
                        <Textarea
                          id="acceptance-criteria-bug"
                          vSize="lg"
                          fullWidth
                          value={acceptanceCriteriaBug}
                          onChange={(e) => setAcceptanceCriteriaBug(e.target.value)}
                        />
                      </InputLabel>
                      <InputLabel label="Bug Priority" htmlFor="bug-priority" formatLabel={requiredLabel}>
                        <SubText>
                          Indicate the severity of the bug you're reporting. This could range from a minor issue
                          that doesn't significantly affect functionality, to a major problem that prevents the
                          system from working properly. This helps us to prioritize bug fixes.
                        </SubText>
                        <Select
                          inputId="bug-priority"
                          menuShouldScrollIntoView
                          isClearable
                          isDisabled={false}
                          fluid
                          maxMenuHeight={200}
                          isSearchable
                          size="lg"
                          options={BugPriorityOptions}
                          onChange={(selectedOption) => {
                            setBugPriority(selectedOption?.value || "");
                          }}
                          value={BugPriorityOptions.find((o) => o.value === bugPriority)}
                        />
                      </InputLabel>
                    </Flex>
                  )}

                  {!!describeRequest && (
                    <Flex direction="column">
                      <SubmitButton
                        variant="primary"
                        intent="info"
                        disabled={disabledSubmitButton}
                        onClick={onSubmit}
                      >
                        Submit Request
                      </SubmitButton>
                    </Flex>
                  )}
                </FormDetails>
              </TabContent>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && createJiraIssueRes.value && (
            <ThankYou
              textVariation={isBugReport || isProductRequestFeedback ? "GEProductBugReport" : "GEGeneric"}
              ticketKey={createJiraIssueRes.value.key}
            />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
