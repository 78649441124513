import { GenerateButton } from "src/components/DeepLinkGenerator/GenerateSection/GenerateButton/GenerateButton";
import { ResetButton } from "src/components/DeepLinkGenerator/GenerateSection/ResetButton/ResetButton";
import { StyledGenerateSection } from "src/components/DeepLinkGenerator/GenerateSection/styles";

type GenerateSectionProps = {
  onGenerateButtonPressed: () => void;
  onResetButtonPressed: () => void;
};
export const GenerateSection = ({
  onGenerateButtonPressed,
  onResetButtonPressed,
}: GenerateSectionProps) => (
  <StyledGenerateSection>
    <ResetButton onResetButtonPressed={onResetButtonPressed} />
    <GenerateButton
      onGenerateButtonPressed={onGenerateButtonPressed}
    />
  </StyledGenerateSection>
);
