import { DescribeRequest } from "src/pages/growth-engineering/data";

export type DepartmentData = {
  icon: string;
  title: string;
  description: string;
  requestFormLink: string;
  jiraProjectKey?: string;
  disabled?: boolean;
  restrictedToUsers?: string[]; // email
  shown?: boolean;
};

export const departmentData: Record<string, DepartmentData[]> = {
  Admin: [
    {
      icon: "../../public/img/keys.svg",
      title: "Sandbox Management",
      description: ``,
      requestFormLink: "/admin/sandbox",
      restrictedToUsers: [
        "anthony.stuvecke@braze.com",
        "zeyuan.zhao@braze.com",
        "maciej.olko@braze.com",
        "bujar.sefa@braze.com",
        "aashita.desai@braze.com",
        "diana.koval@braze.com",
        "david.willis@braze.com",
        "jacqueline.jacobson@braze.com",
      ],
    },
  ],
  "Customer Analytics": [
    {
      icon: "../../public/img/bar-chart.svg",
      title: "Analytics and Reporting for Customers",
      description: `Provide Insights and Reporting for select GTM Activities. (This request type is only available for Strategic Consulting and Customer Success VPs in select cases.)`,
      requestFormLink: "/analytics-reporting",
      jiraProjectKey: "FDDS",
    },
    {
      icon: "../../public/img/data-storage.svg",
      title: "Data Extract Request",
      description: `Obtain Data for EBRs, Compliance Audits, and Marketing related activities`,
      requestFormLink: "/data-extract",
      jiraProjectKey: "FDDS",
    },

    {
      icon: "../../public/img/ds-gsa.svg",
      title: "GSA Data Consulting Request Form",
      description: `If you need assistance with any GSA analytics or data related asks, please submit a request here.`,
      requestFormLink: "/data-services",
      jiraProjectKey: "FDDS",
    },
    {
      icon: "../../public/img/slides_20.svg",
      title: "EBR 2.0",
      description: "Create a EBR2.0 slide deck with Slidebot templates",
      requestFormLink: `/slidebot/ebr`,
      disabled: false,
      jiraProjectKey: "GE",
    },
  ],
  "Product Analytics": [
    {
      icon: "../../public/img/analytics-report.svg",
      title: "Product Analytics Request",
      description: `If you need assistance with any product analytics related asks, please submit a request here.`,
      requestFormLink: "/product-analytics",
      jiraProjectKey: "FDDS",
    },
  ],
  "Growth Data & Engineering": [
    {
      icon: "../../public/img/feedback.svg",
      title: "Product Requests/Feedback",
      description: `New product requests or feature requests for existing products (i.e. Doppelganger, Masquerade, Pilot App)`,
      requestFormLink: `/growth-engineering?describe_request=${DescribeRequest.ProductRequestFeedback}`,
      disabled: false,
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/bug.svg",
      title: "Bug Report",
      description: "File bugs for any Growth Eng alert/data-syncing automation or existing products",
      requestFormLink: `/growth-engineering?describe_request=${DescribeRequest.BugReport}`,
      disabled: false,
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/plg.svg",
      title: "PLG to GTM Automation",
      description: `Form for Product Managers that want to plug their PLG motions into our GTM processes (account owner alerting & Salesforce Automation)`,
      requestFormLink: `/plg-to-gtm`,
      disabled: false,
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/sandbox.svg",
      title: "Sandbox Dashboard Request",
      description: ``,
      requestFormLink: "/sandbox-dashboard",
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/document.svg",
      title: "Template Data File Validator",
      description: ``,
      requestFormLink: "/template-validator",
    },
    {
      icon: "../../public/img/tools.svg",
      title: "New Data Source Request",
      description: `Request for a new data source or data set`,
      requestFormLink: "/ge/new-data-source",
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/tools.svg",
      title: "BI Support",
      description: `Support for Looker and BI tools`,
      requestFormLink: "/ge/bi-support",
      jiraProjectKey: "BI",
    },
    {
      icon: "../../public/img/keys.svg",
      title: "Data Engineering Request",
      description: "Request data engineering support",
      requestFormLink: "/ge/data-engineering",
      jiraProjectKey: "DE",
    },
    {
      icon: "../../public/img/qr-code.svg",
      title: "Pilot QR Code Generator",
      description: "A tool for generating QR codes to be used with Braze Pilot",
      requestFormLink: "/ge/qr-code-gen",
    },
    {
      icon: "../../public/img/architecture-web.svg",
      title: "Free Trial Deep Link Generator",
      description: "A tool for generating deep links for Free Trial dashboards",
      requestFormLink: "/ge/deeplink-generator",
    },
  ],
  "Presentation Automation": [
    {
      icon: "../../public/img/slides.svg",
      title: "EBR Generation Request",
      description: "Create a EBR slide deck with Slidebot templates",
      requestFormLink: `/slidebot`,
      disabled: false,
      jiraProjectKey: "GE",
    },
    {
      icon: "../../public/img/pitch_deck.svg",
      title: "Pitch Deck Generation Request",
      description: "Create a Pitch slide deck",
      requestFormLink: `/slidebot/pitchdeck`,
      disabled: false,
      jiraProjectKey: "GE",
    },
  ],
};
