import { usePost } from "./usePost";
import { API_BASE_URL } from "src/config";
import { useCallback, useState } from "react";
import { SandBoxFreeTrialInput } from "./types";

export type InsertSandboxSubmissionResponse = {
  id: string;
};

export const useInsertSandboxSubmission = () => {
  const [post, state] = usePost<InsertSandboxSubmissionResponse, SandBoxFreeTrialInput>({
    url: `${API_BASE_URL}/api/sandbox/submission`,
  });
  const [response, setResponse] = useState<InsertSandboxSubmissionResponse | null>(null);

  const insertSandboxSubmission = useCallback(
    async (inputData: SandBoxFreeTrialInput) => {
      const res = await post(inputData);
      setResponse(res);
      return res;
    },
    [post]
  );

  return { ...state, insertSandboxSubmission, response };
};
