import { DefaultOptionType } from "@braze/beacon-lab-select";
import { useMemo } from "react";

export const useValidation = (
  url: string | URL | null,
  assetName: string | null,
  workspace: DefaultOptionType | null,
  assetType: DefaultOptionType | null,
  extraParams: string | null
) => {
  return useMemo(
    () => ({
      isValid: (() => {
        const invalidFields = [];

        if (assetType && assetType.value !== "page" && !assetName) {
          invalidFields.push("Asset Name");
        }

        if (assetType && assetType.value === "page" && !url) {
          invalidFields.push("URL");
        }

        if (!assetType) {
          invalidFields.push("Asset type");
        }

        const extraParamsPattern = /^(\w+=[^&]+)(&\w+=[^&]+)*$/;
        if (extraParams && !extraParamsPattern.test(extraParams)) {
          invalidFields.push("Extra params");
        }

        return invalidFields.length === 0;
      })(),
      invalidInputs: (() => {
        const invalidFields = [];

        if (assetType && assetType.value !== "page" && !assetName) {
          invalidFields.push("Asset Name");
        }

        if (assetType && assetType.value === "page" && !url) {
          invalidFields.push("URL");
        }

        if (!assetType) {
          invalidFields.push("Asset type");
        }

        return invalidFields.join(", ");
      })(),
    }),
    [url, workspace, assetType, assetName]
  );
};
