import { Button, Icon } from "@braze/beacon-core";

type ResetButtonProps = {
  onResetButtonPressed: () => void;
};

export const ResetButton = ({ onResetButtonPressed }: ResetButtonProps) => (
  <Button
    variant="quaternary"
    intent="info"
    size="lg"
    onClick={onResetButtonPressed}
  >
    Reset
    <Icon name="sync" />
  </Button>
);
