import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";

import { SolconResult } from "src/hooks/types";
import { useGetSolconDetails } from "src/hooks/useGetSolconDetails";
import { OptionsLabelValue } from "src/components/types";

type SolconDetailsSearchSelect = {
  isDisabled?: boolean;
  onSelectSolconDetail: (solconDetail: SolconResult) => void;
};

export const SolconDetailsSearchSelect = ({ isDisabled, onSelectSolconDetail }: SolconDetailsSearchSelect) => {
  const [solconDetailsOptions, setSolconDetailsOptions] = useState<OptionsLabelValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsLabelValue[]>([]);

  const { value, loading } = useGetSolconDetails();

  useEffect(() => {
    if (value?.length) {
      const res = (value || []).map((solconDetail: SolconResult) => ({
        label: `${solconDetail.SOLCON_NAME} [${solconDetail.SOLCON_TITLE}]`,
        value: solconDetail,
        description: `Email: ${solconDetail.SOLCON_EMAIL}`,
      }));
      setSolconDetailsOptions(res);
    }
  }, [value]);

  return (
    <Select
      inputId="solcon-details"
      isClearable
      isSearchable
      fluid
      isDisabled={isDisabled}
      options={solconDetailsOptions}
      menuPortalTarget={document.body}
      isLoading={loading}
      value={selectedOptions}
      placeholder="Type in to see results..."
      onChange={(selectedOption) => {
        setSelectedOptions(selectedOption);
        if (selectedOption) {
          onSelectSolconDetail(selectedOption.value);
        }
      }}
    />
  );
};
