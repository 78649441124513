import {
  AutomationPriorityOptions,
  BugPriorityOptions,
  DescribeRequest,
  RequestCategory,
} from "src/pages/growth-engineering/data";

export const getJiraPriorityId = (
  requestType: null | string | undefined,
  automationPriority: string,
  bugPriority: string,
  requestCategory: string
) => {
  let priorityObj;

  if (requestType === DescribeRequest.WorkflowAutomation) {
    priorityObj = AutomationPriorityOptions.find((o) => o.value === automationPriority);
  } else if (requestType === DescribeRequest.BugReport) {
    priorityObj = BugPriorityOptions.find((o) => o.value === bugPriority);
  } else if (
    requestType === DescribeRequest.ProductRequestFeedback &&
    requestCategory === RequestCategory.WebEngineering
  ) {
    priorityObj = BugPriorityOptions.find((o) => o.value === bugPriority);
  }
  //returns a default of P2 if there isn't a priority
  return priorityObj ? priorityObj.jiraId : "3";
};
