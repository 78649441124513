import { DefaultOptionType } from "@braze/beacon-lab-select";
import React from "react";
import { BasicDetailsSelect } from "./BasicDetailsSelect/BasicDetailsSelect";
import { DemoName } from "./DemoName/DemoName";
import { QRCodeGeneratorSection } from "src/components/QRCodeGenerator/styles";
import { Box, Heading } from "@braze/beacon-core";

type BasicDetailsProps = {
  demoName: string;
  demoStartPoint: DefaultOptionType | null;
  onDemoNameChanged: (name: string) => void;
  onDemoStartPointChanged: (startPoint: DefaultOptionType) => void;
};
export const BasicDetails = ({
  demoName,
  demoStartPoint,
  onDemoNameChanged,
  onDemoStartPointChanged,
}: BasicDetailsProps) => (
  <Box>
    <Heading level={3}>Basic Details</Heading>
    <QRCodeGeneratorSection>
      <DemoName demoName={demoName} onDemoNameChanged={onDemoNameChanged} />
      <BasicDetailsSelect demoStartPoint={demoStartPoint} onDemoStartPointChanged={onDemoStartPointChanged} />
    </QRCodeGeneratorSection>
  </Box>
);
