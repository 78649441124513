import { useNavigate } from "react-router-dom";
import { Description, StyledTile, TileHeader, TileHeading } from "./styles";

type TileProps = {
  disabled?: boolean;
  icon: string;
  title: string;
  description: string;
  requestFormLink: string;
  shown: boolean;
};

export const Tile = ({ disabled, icon, title, description, requestFormLink, shown }: TileProps) => {
  const history = useNavigate();
  const requestFormRedirect = (link: string) => history(link);
  if (!shown) {
    return null;
  }
  return (
    <StyledTile
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          requestFormRedirect(requestFormLink);
        }
      }}
    >
      <TileHeader>
        <img src={icon} alt={title} />
        <TileHeading level={4}>{title}</TileHeading>
      </TileHeader>
      <div style={{ border: "none" }}>
        <Description>{description}</Description>
      </div>
    </StyledTile>
  );
};
