import { DataServicesFormFieldType } from "src/pages/data-services/types";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { JiraConfig } from "src/pages/growth/jiraRouting";
import {
  AnalyticsCapability,
  CompanyCategory,
  DataEnhancement,
  Need,
  RequestCategory,
  Team,
} from "src/pages/data-services/data";

export const createJiraDetails = (formState: DataServicesFormFieldType): string => {
  const userInfo = getUserInformation();
  const {
    companyCategory,
    mauCategory,
    customerName,
    gsaCustomer,
    appGroupId,
    requestCategory,
    analyticsCapability,
    analyticsOther,
    dataEnhancement,
    dataEnhancementOther,
    dataExport,
    requestTitle,
    requestSummary,
    need,
    team,
    needOther,
    urgency,
    teamOther,
  } = formState;

  // Issue description
  let issueData = `
      *Reporter*: ${userInfo?.email}
      *Company Classification*: ${companyCategory}
      *MAU*: ${mauCategory}
      *App Group Id*: ${appGroupId}`;
  if (companyCategory !== CompanyCategory.GSA) {
    issueData += `
            *Customer Name*: ${customerName}
        `;
  }
  if (companyCategory === CompanyCategory.GSA) {
    issueData += `
            *GSA Customer*: ${gsaCustomer}
        `;
  }
  issueData += `
      *Request Title*: ${requestTitle}
      *Request Summary*: ${requestSummary}
      *Request Category*: ${requestCategory}`;
  if (requestCategory === RequestCategory.Analytics) {
    issueData += `
                *What type of Analytics Capabilities is the customer looking for?*: ${analyticsCapability}
            `;
    if (analyticsCapability === AnalyticsCapability.Other) {
      issueData += `*Analytics Capability (Other)*: ${analyticsOther}`;
    }
  }
  if (requestCategory === RequestCategory.DataEnhancements) {
    issueData += `
            *What Data Enhancement Needs?*: ${dataEnhancement}
        `;
    if (dataEnhancement === DataEnhancement.Other) {
      issueData += `*Data Enhancement (Other)*: ${dataEnhancementOther}`;
    }
  }
  if (requestCategory === RequestCategory.DataExport) {
    issueData += `
            *Which Data Export Capability?*: ${dataExport} 
        `;
  }

  if (companyCategory === CompanyCategory.GSA) {
    issueData += `
        *Urgency*: ${urgency}`;
  }

  if (companyCategory !== CompanyCategory.GSA) {
    if (need !== Need.Other) {
      issueData += `
            *Need*: ${need}`;
    } else {
      issueData += `
            *Need (Other)*: ${needOther}`;
    }
  }
  if (team === Team.Other) {
    issueData += `
        *Team (Other)*: ${teamOther}
        `;
  } else {
    issueData += `
    *Team*: ${team}`;
  }

  return issueData;
};

// Create jira issue
export const createJiraIssueReq = (
  jiraConfig: JiraConfig,
  formState: DataServicesFormFieldType
): JiraIssueRequest => {
  const issueData = createJiraDetails(formState);
  const jiraIssueRequest: JiraIssueRequest = {
    fields: {
      project: {
        key: jiraConfig.jiraProjectKey,
      },
      description: issueData,
      summary: `${formState.requestTitle}`,
      issuetype: {
        name: "Task",
      },
      // eslint-disable-next-line camelcase
      customfield_10000: jiraConfig.epicLink,
      labels: jiraConfig.labels,
    },
  };
  return jiraIssueRequest;
};
