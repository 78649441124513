import { MutationOptions, UseMutationResult, useMutation } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";
import { fetcher } from "./fetcher";
import { RequestMethod } from "./types";

export const useApiMutation = <
  TData = unknown,
  TError = unknown,
  TVariables extends Record<string, unknown> | undefined = Record<string, unknown>,
  TMutateFn = (variables: TVariables) => Promise<TData>
>(
  method: Exclude<RequestMethod, "GET">,
  route: string,
  options?: MutationOptions<TData, TError, TVariables, TMutateFn>
): UseMutationResult<TData, TError, TVariables, TMutateFn> => {
  const { authState } = useOktaAuth();

  return useMutation({
    mutationFn: (variables: TVariables) =>
      fetcher<TData>(route, authState?.idToken?.idToken, {
        method,
        body: variables,
      }),
    ...options,
  });
};
