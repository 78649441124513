/* eslint-disable camelcase */
import { FormField } from "..";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { getUserInformation } from "src/utils";
import { JiraConfig } from "src/pages/growth/jiraRouting";
import { ProductPriority } from "src/pages/product-analytics/data";

export const createJiraDetails = (formState: FormField): string => {
  const userInfo = getUserInformation();
  const {
    title,
    productArea,
    productDivision,
    productPriority,
    additionalInfo,
    documentLink,
    priorityReason,
    stakeholders,
    summary,
  } = formState;

  // Issue description
  let issueData = `
      *Reporter*: ${userInfo?.email}
      *Title*: ${title}
      *Summary*: ${summary}
      *Product Area*: ${productArea}
      *Product Division*: ${productDivision}
      *Confluence / Google Documentation Link*: ${documentLink}
      *Product Priority*: ${productPriority}`;
  if (productPriority === ProductPriority.High) {
    issueData += `
      *Reason For Priority*: ${priorityReason}`;
  }
  issueData += `
      *Key Stakeholders*: ${stakeholders}
      *Additional Information*: ${additionalInfo}
    `;
  return issueData;
};

// Create jira issue
export const createJiraIssueReq = (jiraConfig: JiraConfig, formState: FormField): JiraIssueRequest => {
  const issueData = createJiraDetails(formState);
  const jiraIssueRequest: JiraIssueRequest = {
    fields: {
      project: {
        key: jiraConfig.jiraProjectKey,
      },
      description: issueData,
      summary: `${formState.title}`,
      issuetype: {
        name: "Task",
      },
      customfield_10000: jiraConfig.epicLink,
      labels: jiraConfig.labels,
    },
  };
  return jiraIssueRequest;
};
