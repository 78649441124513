import { Button, Icon } from "@braze/beacon-core";
import React from "react";

type GenerateButtonProps = {
  onGenerateButtonPressed: () => void;
  loading?: boolean;
};

export const GenerateButton: React.FC<GenerateButtonProps> = ({ onGenerateButtonPressed, loading = false }) => (
  <Button onClick={onGenerateButtonPressed}>
    {loading ? (
      <span>Generating...</span>
    ) : (
      <>
        <span>Generate Link</span>
        <Icon name="link" />
      </>
    )}
  </Button>
);
