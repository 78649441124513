import { API_BASE_URL } from "src/config";
import { AccountResult } from "./types";
import { useFetch } from "./useFetch";

export const useGetCompanies = (companyFetchDisabled: boolean, companyPrefix?: string) =>
  useFetch<AccountResult[]>({
    url: `${API_BASE_URL}/api/company/search`,
    lazyload: companyFetchDisabled,
    ...(companyPrefix && { params: { name: companyPrefix } }), // do not add companyPrefix params if not provided
  });
