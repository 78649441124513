import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";

import { AccountResult, AppGroupResult } from "src/hooks/types";
import { useGetAppGroups } from "src/hooks/useGetAppGroups";
import { OptionsLabelValue } from "src/components/types";

type AppGroupSearchSelectProps = {
  onAppGroupSelect: (appGroup: AppGroupResult[]) => void;
  selectedAccount?: AccountResult;
  isMulti?: boolean;
};

export const AppGroupSearchSelect = ({ onAppGroupSelect, selectedAccount, isMulti }: AppGroupSearchSelectProps) => {
  const [appGroupOptions, setAppGroupOptions] = useState<OptionsLabelValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsLabelValue[]>([]);

  const { value, loading, refetch } = useGetAppGroups(!selectedAccount, selectedAccount?.EXTERNAL_ID || "");

  useEffect(() => {
    if (value) {
      const res = (value || []).map((app: AppGroupResult) => ({
        label: app.APP_GROUP_NAME,
        value: app.APP_GROUP_ID,
        description: `ID: ${app.APP_GROUP_ID}`,
      }));
      setAppGroupOptions(res);
    }
  }, [value]);

  useEffect(() => {
    setSelectedOptions([]);
    onAppGroupSelect([]);
    if (selectedAccount === undefined) {
      setAppGroupOptions([]);
    } else {
      refetch({ id: selectedAccount.EXTERNAL_ID });
    }
  }, [selectedAccount]);
  const isMultiple = isMulti === undefined ? true : isMulti;

  return (
    <Select
      inputId="appGroup"
      isMulti={isMultiple}
      isClearable
      isDisabled={false}
      isSearchable
      fluid
      options={appGroupOptions}
      menuPortalTarget={document.body}
      value={selectedOptions}
      isLoading={loading}
      placeholder="Select an app group..."
      onChange={(selectedOptions) => {
        setSelectedOptions(selectedOptions);
        if (!isMultiple) {
          onAppGroupSelect(
            selectedOptions ? [{ APP_GROUP_NAME: selectedOptions.label, APP_GROUP_ID: selectedOptions.value }] : []
          );
        } else {
          const options: AppGroupResult[] = (selectedOptions || []).map((selectedOption: OptionsLabelValue) => ({
            APP_GROUP_NAME: selectedOption.label,
            APP_GROUP_ID: selectedOption.value,
          }));
          onAppGroupSelect(options);
        }
      }}
    />
  );
};
