import { FieldLabel, StatusText } from "@braze/beacon-core";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  RequestForm,
  SubText,
} from "./../../components/commonStyles";
import { NavigateBackButton } from "./../../components/NavigateBackButton";
import { ChangeEvent, useState } from "react";
import { usePost } from "./../../hooks/usePost";
import { API_BASE_URL } from "./../../config";
import _ from "lodash";
import { LoadingSpinner } from "@braze/beacon-labs-spinner";
import { RequestSummaryTextArea } from "./styles";

export type TemplateValidatorRequest = {
  text?: string;
};

export type TemplateValidatorResponse = {
  message: string;
  errors?: string[];
};

export const TemplateValidator = () => {
  const [templateData, setTemplateData] = useState<string>("");
  const [message, setMessage] = useState<string | undefined>("");
  const [errors, setErrors] = useState<string[] | undefined>([]);

  const [validateYaml, validateYamlResponse] = usePost<TemplateValidatorResponse, TemplateValidatorRequest>({
    url: `${API_BASE_URL}/api/sandbox/validate`,
  });

  const validate = _.debounce(async (text: string) => {
    setErrors([]);
    setMessage("");
    const res = await validateYaml({ text });
    setMessage(res?.message);
    setErrors(res?.errors);
  }, 500);

  const handleOnChange = (e: ChangeEvent) => {
    setTemplateData((e.target as HTMLInputElement).value);
    validate((e.target as HTMLInputElement).value);
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "0rem" }}>
        <FormPanel>
          <FormPanelBody>
            <FormHeading level={2}>Template Data File Validator</FormHeading>
            <FormDetails>
              <FieldLabel label="Template Data">
                <SubText>
                  The textbox below will validate a YAML file and output any errors below the text field. Please pay
                  attention to indentation, wrong keys and incorrect values. If the YAML file is invalid or cannot
                  be parsed, all new and existing app groups will be affected - we won`&apos;`t be able to generate
                  new data.
                  <p>
                    <b>
                      Note: We only update the files twice a month - on the first and third Friday of the month.
                    </b>
                  </p>
                </SubText>
                <RequestSummaryTextArea
                  id="request-summary"
                  placeholder=""
                  value={templateData}
                  onChange={handleOnChange}
                />
              </FieldLabel>
              {validateYamlResponse.loading && <LoadingSpinner />}
              {validateYamlResponse?.error && (
                <StatusText displayIcon status="danger">
                  {validateYamlResponse?.error}
                </StatusText>
              )}
              {!!errors?.length && message && (
                <>
                  <StatusText displayIcon status="danger">
                    {message}
                  </StatusText>
                  <ul>
                    {errors.map((error, idx) => (
                      <li key={idx.toString()}>
                        <SubText>{error}</SubText>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!errors?.length &&
                templateData &&
                !validateYamlResponse.loading &&
                validateYamlResponse.called &&
                message && (
                  <StatusText displayIcon status="success">
                    {message}
                  </StatusText>
                )}
            </FormDetails>
          </FormPanelBody>
        </FormPanel>
      </RequestForm>
    </>
  );
};
