import styled from "styled-components";
import { Box } from "@braze/beacon-core";

export const QRCodeGeneratorInput = styled(Box)`
  width: 300px;
  margin-right: 25px;
`;

export const QRCodeGeneratorSection = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
`;
