import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "src/config";
import { FetcherOptions, RequestMethod } from "./types";

export const axiosApiInstance = axios.create({
  baseURL: `${API_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetcher = async <TResponse, TBody = Record<string, unknown> | undefined>(
  route: string,
  token: string | undefined,
  options: FetcherOptions<TBody>
): Promise<TResponse> => {
  const headers = {
    AUTHORIZATION: token,
  };

  const methods: Record<RequestMethod, () => Promise<AxiosResponse<TResponse>>> = {
    GET: async () =>
      await axiosApiInstance.get<TResponse>(route, {
        params: options.params,
        headers: headers,
      }),
    POST: async () =>
      await axiosApiInstance.post<TResponse>(route, options.body, {
        headers: headers,
      }),
    PUT: async () =>
      await axiosApiInstance.put<TResponse>(route, options.body, {
        headers: headers,
      }),
  };

  const { data } = await methods[options.method]();
  return data;
};
