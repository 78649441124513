import { Input, Textarea, makeToast } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  Hyperlink,
  InputLabel,
  LongLabel,
  RequestForm,
  StyledBodyText,
  SubText,
  SubmitButton,
} from "src/components/commonStyles";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { requiredLabel } from "src/components/required";
import { useCreateIssue } from "src/hooks/useCreateIssue";
import { useForm } from "src/hooks/useForm";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { ThankYou } from "src/pages/thank-you";
import { PlgMotion, PlgMotionOptions } from "./data";
import { createJiraIssueReq } from "./utils/jiraUtils";

const plgConfluenceDoc = "https://confluence.braze.com/pages/viewpage.action?pageId=245507003";

export type FormField = {
  productFeature: string;
  plgMotion: PlgMotion;
  requiresSalesforceOpp: string;
  customEvent: string;
  typeOfInfo: string;
  slackChannelId: string;
  draftCopy: string;
  callToAction: string;
};

export const ProductLedGrowth = () => {
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();
  // Provide defaults for required values
  const { formState, setFormFieldValue } = useForm<FormField>({
    productFeature: "",
    plgMotion: PlgMotion.Upsell,
    requiresSalesforceOpp: "",
    customEvent: "",
    typeOfInfo: "",
    slackChannelId: "",
    draftCopy: "",
    callToAction: "",
  });

  const {
    productFeature,
    plgMotion,
    requiresSalesforceOpp,
    customEvent,
    typeOfInfo,
    slackChannelId,
    draftCopy,
    callToAction,
  } = formState;

  // Disable submit button if following aren't populated
  const submitDisabled =
    !(
      !!productFeature &&
      !!plgMotion &&
      !!requiresSalesforceOpp &&
      !!customEvent &&
      !!typeOfInfo &&
      !!slackChannelId &&
      !!draftCopy &&
      !!callToAction
    ) || createJiraIssueRes.loading;

  const onSubmit = async () => {
    const jiraConfig = getJiraConfig("PLG to GTM Automation", formState);
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", formState);
      return; // Required
    }
    const jiraIssueRequest = createJiraIssueReq(jiraConfig, formState);
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "18rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>Product Led Growth Request</FormHeading>
              <FormDetails>
                <StyledBodyText>
                  Please refer to this{" "}
                  <Hyperlink href={plgConfluenceDoc} target="_blank">
                    confluence doc
                  </Hyperlink>{" "}
                  for details on the overarching PLG Sales Automation.
                </StyledBodyText>
                <InputLabel
                  label="What is the relevant product feature you're running the PLG experience on?"
                  htmlFor="product-feature"
                  formatLabel={requiredLabel}
                >
                  <SubText>E.g. Segment Extensions</SubText>
                  <Input
                    id="product-feature"
                    placeholder=""
                    required
                    fullWidth
                    value={productFeature}
                    onChange={(e) => setFormFieldValue("productFeature", e.target.value)}
                  />
                </InputLabel>
                <LongLabel
                  label="What type of PLG motion are you doing: Learning, Upselling, Free Trialing"
                  htmlFor="plg-motion"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="plg-motion"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    fluid
                    options={PlgMotionOptions}
                    onChange={(selectedOption) => {
                      setFormFieldValue("plgMotion", selectedOption?.value);
                    }}
                    value={PlgMotionOptions.find((o) => o.value === plgMotion)}
                  />
                </LongLabel>
                <LongLabel
                  label="Do you believe your PLG experience requires the automatic creation of a Salesforce opportunity once the user has converted?"
                  htmlFor="requires-salesforce-opp"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    If yes, we will review with the sales op team to confirm whether your PLG experience warrants an
                    automatic salesforce opportunity creation.
                  </SubText>
                  <Input
                    id="requires-salesforce-opp"
                    placeholder=""
                    fullWidth
                    value={requiresSalesforceOpp}
                    onChange={(e) => setFormFieldValue("requiresSalesforceOpp", e.target.value)}
                  />
                </LongLabel>
                <LongLabel
                  label="Specify the Braze custom event and associated properties will you fire when a user completes the conversion event. (Eg. pm.plg.pageview)"
                  htmlFor="custom-event"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    All PLG custom events should adhere to the schema set&nbsp;
                    <Hyperlink
                      href="https://confluence.braze.com/pages/viewpage.action?pageId=245507003#PLG%3C%3EGTMWorkflow:Alerting&SalesforceAutomation-PLGCustomEventsSchema"
                      target="_blank"
                    >
                      here
                    </Hyperlink>
                  </SubText>
                  <Input
                    id="custom-event"
                    placeholder=""
                    required
                    fullWidth
                    value={customEvent}
                    onChange={(e) => setFormFieldValue("customEvent", e.target.value)}
                  />
                </LongLabel>

                <LongLabel
                  label='Upon converting, will the dashboard user be inputting any type of information or message? If yes, ensure the input is passed along in the plg custom event with a specific property like "input".'
                  htmlFor="type-of-info"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    E.g. Usually for Upsells, Dashboard user may specify how many more of an allotment (credits,
                    SMS, Email) they would like to buy
                  </SubText>
                  <Textarea
                    id="type-of-info"
                    placeholder=""
                    fullWidth
                    value={typeOfInfo}
                    vSize="lg"
                    onChange={(e) => setFormFieldValue("typeOfInfo", e.target.value)}
                  />
                </LongLabel>

                <InputLabel
                  label="Slack Channel ID for your PLG Feature"
                  htmlFor="slack-channel-id"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    By default, Growth will be sending slack alerts to account owners and the relevant
                    discuss-client channels when a dashboard users has converted. We encourage PMs to setup a
                    dedicated channel so they can have insight into all the converted PLG flows and the relevant
                    account owners that have been pinged.
                  </SubText>
                  <Input
                    id="slack-channel-id"
                    placeholder=""
                    required
                    fullWidth
                    value={slackChannelId}
                    onChange={(e) => setFormFieldValue("slackChannelId", e.target.value)}
                  />
                </InputLabel>
                <LongLabel
                  label="Please share draft of the copy you would like to message the client after they have converted from the PLG dashboard experience as a google doc."
                  htmlFor="draft-copy"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    This content copy will be inputted in a Braze@Braze canvas that will email the user with more
                    information or next steps. The message can be sent as plain text or with more sophisticated
                    email HTML. Example found&nbsp;
                    <Hyperlink href="https://internal-dashboard-01.braze.com/sign_in" target="_blank">
                      here
                    </Hyperlink>
                  </SubText>
                  <Textarea
                    id="draft-copy"
                    placeholder=""
                    fullWidth
                    value={draftCopy}
                    onChange={(e) => setFormFieldValue("draftCopy", e.target.value)}
                  />
                </LongLabel>
                <InputLabel
                  label="Do you have a specific call-to-action for the Account Owner?"
                  htmlFor="call-to-action"
                  formatLabel={requiredLabel}
                >
                  <SubText>
                    When sending the internal slack alert, is there specific playbook or call-to-action for the
                    account owner? Example found{" "}
                    <Hyperlink
                      href="https://confluence.braze.com/pages/viewpage.action?pageId=245502615"
                      target="_blank"
                    >
                      here
                    </Hyperlink>
                  </SubText>
                  <Input
                    id="call-to-action"
                    placeholder=""
                    required
                    fullWidth
                    value={callToAction}
                    onChange={(e) => setFormFieldValue("callToAction", e.target.value)}
                  />
                </InputLabel>
                <SubmitButton variant="primary" intent="info" disabled={submitDisabled} onClick={onSubmit}>
                  Submit Request
                </SubmitButton>
              </FormDetails>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
