import { departmentData } from "./departmentData";
import { getUserInformation } from "src/utils";
import { Background, TileContainer } from "./growth.styles";
import { Department, Tile } from "src/components/Growth";

export const Growth = () => {
  const userInfo = getUserInformation();

  const isTilePermittedToUser = (restrictedToUsers: string[] | undefined) => {
    if (
      restrictedToUsers?.length &&
      !!userInfo?.email &&
      !restrictedToUsers?.includes(userInfo?.email.toLowerCase())
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Background>
        <TileContainer>
          {Object.entries(departmentData).map(([departmentName, departmentData]) => (
            <Department key={departmentName} departmentName={departmentName}>
              {departmentData.map((d) => (
                <Tile
                  key={d.title}
                  shown={isTilePermittedToUser(d.restrictedToUsers)}
                  disabled={d.disabled}
                  icon={d.icon}
                  title={d.title}
                  description={d.description}
                  requestFormLink={d.requestFormLink}
                />
              ))}
            </Department>
          ))}
        </TileContainer>
      </Background>
    </>
  );
};
