import { FieldLabel, Input } from "@braze/beacon-core";
import React from "react";
import { QRCodeGeneratorInput, QRCodeGeneratorSection } from "src/components/QRCodeGenerator/styles";

type APIKeyEntryProps = {
  androidApiKey: string;
  iOSApiKey: string;
  onAndroidApiKeyChanged: (droidApiKey: string) => void;
  oniOSApiKeyChanged: (iOSApiKey: string) => void;
};
export const APIKeyEntry = ({
  androidApiKey,
  iOSApiKey,
  onAndroidApiKeyChanged,
  oniOSApiKeyChanged,
}: APIKeyEntryProps) => (
  <QRCodeGeneratorSection>
    <QRCodeGeneratorInput>
      <FieldLabel label="Android API Key" enforce="required">
        <Input
          placeholder="Type in a value"
          value={androidApiKey}
          onChange={(e) => {
            onAndroidApiKeyChanged(e.target.value);
          }}
        />
      </FieldLabel>
    </QRCodeGeneratorInput>
    <QRCodeGeneratorInput>
      <FieldLabel className="iOSApiKeyInput" label="iOS API Key" enforce="required">
        <Input
          placeholder="Type in a value"
          value={iOSApiKey}
          onChange={(e) => {
            oniOSApiKeyChanged(e.target.value);
          }}
        />
      </FieldLabel>
    </QRCodeGeneratorInput>
  </QRCodeGeneratorSection>
);
