import { EmailListBox } from "./styles";
import { Contact, SandBoxUserSubmissionStatus } from "src/hooks/types";
import { EmailItem } from "./EmaiItem";
import { Heading } from "@braze/beacon-core";

export const EmailList = ({
  contacts,
  provisionSubmitted,
  provisionedUsers,
  unprovisionedUsers,
}: {
  contacts: Contact[];
  provisionSubmitted: boolean;
  provisionedUsers: string[];
  unprovisionedUsers: SandBoxUserSubmissionStatus[];
}) => {
  if (provisionSubmitted) {
    return (
      <>
        {provisionedUsers.length > 0 && (
          <>
            <Heading level={5}>Provisioned Users:</Heading>
            <EmailListBox>
              {provisionedUsers.map((email, index) => (
                <EmailItem
                  key={email}
                  email={email}
                  status="Success"
                  last={index === provisionedUsers.length - 1}
                />
              ))}
            </EmailListBox>
          </>
        )}
        {unprovisionedUsers.length > 0 && (
          <>
            <Heading level={5}>Unprovisioned Users:</Heading>
            <EmailListBox>
              {unprovisionedUsers.map((c, index) => (
                <EmailItem
                  key={c.userName}
                  email={c.userName}
                  status={`Failed: ${c.message}`}
                  last={index === unprovisionedUsers.length - 1}
                />
              ))}
            </EmailListBox>
          </>
        )}
      </>
    );
  }

  return (
    <EmailListBox>
      {contacts.map((c, index) => (
        <EmailItem key={c.email} email={c.email} last={index === contacts.length - 1} />
      ))}
    </EmailListBox>
  );
};
