import { BodyText, Label } from "@braze/beacon-core";
import { useNavigate } from "react-router-dom";
import {
  HeadingLabel,
  HighlightedText,
  NewRequestButton,
  StyledFlex,
  StyledLink,
  StyledOL,
  StyledPanelBody,
  TorchieImg,
} from "./styles";

type Props = {
  ticketKey?: string;
  textVariation?: "GEGeneric" | "Slidebot" | "GEProductBugReport";
};

export const ThankYou = ({ ticketKey, textVariation }: Props) => {
  const history = useNavigate();
  const homePage = () => history("/");

  if (textVariation && textVariation === "GEGeneric" && !ticketKey) {
    return null;
  }

  const renderContent = () => {
    switch (textVariation) {
      case "GEGeneric":
        return (
          <>
            <Label>Growth will review your request and complete the following:</Label>

            <Label>
              <StyledOL>
                <li>
                  Initial Communication - Within 5 business days of receiving the request, the relevant Growth team
                  will fStyledOLlow up with any clarifying questions over Jira.
                </li>

                <li>
                  Commitment Decision - Within 10 business days of receiving the request, the relevant Growth team
                  will either commit to or reject the request based on feasibility, resources and impact.
                </li>
              </StyledOL>
            </Label>
            <Label>
              Bug Request Response Times:
              <ul>
                <li>P0 - Same Day</li>
                <li>P1 - Same Day</li>
                <li>P2 - 5 business days</li>
                <li>P3 - 7 business days</li>
              </ul>
            </Label>
          </>
        );
      case "GEProductBugReport":
        return (
          <>
            <Label>
              For all product feedback and requests, Growth will take these as input on forming our strategic
              roadmap. We will do the following:
            </Label>
            <BodyText>
              <StyledOL>
                <li>
                  <HighlightedText>Review - </HighlightedText>Growth will review all feedback within 10 business
                  days and follow up with any clarifying questions over Jira.
                </li>
                <li>
                  <HighlightedText>Transactional Product Development -</HighlightedText> For any transactional
                  product features where there is pre-existing alignment with stakeholder leadership, the relevant
                  Growth team will reach out in 10 business days with commitment and general timelines given our
                  existing resourcing and roadmap.{" "}
                </li>
              </StyledOL>
            </BodyText>
            <Label>
              Bug Request Response Times:
              <ul>
                <li>P0 - Same Day</li>
                <li>P1 - Same Day</li>
                <li>P2 - 5 business days</li>
                <li>P3 - 7 business days</li>
              </ul>
            </Label>
          </>
        );
      case "Slidebot":
        return <Label>You should receive a Slack notification regarding your slides!</Label>;
      default:
        return null;
    }
  };
  return (
    <>
      <StyledPanelBody>
        <StyledFlex direction="column" alignItems="center">
          <HeadingLabel>
            Thank you for submitting your{" "}
            {textVariation === "GEProductBugReport" ? "request/bug report" : "request"}.
          </HeadingLabel>

          {renderContent()}
          {textVariation !== "Slidebot" && (
            <Label>
              <StyledLink target="blank" href={`https://jira.braze.com/browse/${ticketKey}`}>
                Click here to review your request.
              </StyledLink>
            </Label>
          )}
          <TorchieImg src="../../../public/img/torchie.svg" alt="torchie-icon" />
          <NewRequestButton onClick={homePage}>Start a New Request</NewRequestButton>
        </StyledFlex>
      </StyledPanelBody>
    </>
  );
};
