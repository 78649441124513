import styled from "styled-components";
import { Box } from "@braze/beacon-core";

export const QRCodeModalBody = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const QRCodeContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #a8b3b8;
  padding: 10px;
  margin: auto;
  width: fit-content;
`;
