export const FindCustomerData = [
  {
    AssetName: "Advanced EBR Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Quantifying Market Value", "Expanding the Relationship", "Establish Braze as strategic advisors"],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Advanced EBR Dashboard",
    Link: "https://braze.looker.com/dashboards/campaigns_model_full::advanced_ebr_dashboard",
    Description:
      "Dashboard that provides message engagement + deliverability metrics and industry benchmarks useful for EBRs. Most useful for identifying trend information and determining Braze's value add over time. Adds tag information and gets more granular than the Basic EBR Stats Dashboard.",
  },
  {
    AssetName: "Basic EBR Stats Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Quantifying Market Value", "Expanding the Relationship", "Establish Braze as strategic advisors"],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Basic EBR Stats Dashboard",
    Link: "https://braze.looker.com/dashboards/campaigns_model_full::basic_ebr_stats_dashboard",
    Description:
      "Dashboard that provides message engagement + deliverability metrics and industry benchmarks useful for EBRs. Most useful for identifying trend information and determining Braze's value add over time.",
  },
  {
    AssetName: "Braze Deliverability Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Braze Deliverability Dashboard",
    Link: "http://braze.looker.com/dashboards/432",
    Description:
      "Email engagement metrics like send, open, and delivery rates. Includes mailbox provider. Helps determine whether a customer needs help reaching email users or has an email deliverability issue.",
  },
  {
    AssetName: "Campaign Creative Elements",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Quantifying Market Value",
      "Prioritizing New Features & Tools",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Establish Braze as strategic advisors",
      "Leveraging Data In A Meaningful Way",
      "Build Out User Journeys",
    ],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Campaign Creative Elements",
    Link: "https://braze.looker.com/dashboards/industry_solutions%3A%3Acampaign_creative_elements",
    Description:
      "This Dashboard provides industry benchmarks around various features. The filtered example shows liquid uplift per channel on purchase-related conversion criteria for the Retail & eCommerce industry. You can also filter on campaign audiences or Explore from the tiles to filter on a single company.",
  },
  {
    AssetName: "Custom Attributes and Custom Events",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Bad Initial Setup",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "Custom Data",
    LinkTitle: "Custom Attributes and Custom Events",
    Link: "https://braze.looker.com/dashboards/data_points%3A%3Acustom_attributes_and_custom_events",
    Description:
      "Dashboard that provides a breakdown of custom attribute and event usage for companies and/or app groups over a time period. Useful for conversations around paring down attributes tracked, being more specific with goals around custom tracking, and building out user journeys using custom tracking.",
  },
  {
    AssetName: "Custom Events with Event Properties",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Bad Initial Setup",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "Custom Data",
    LinkTitle: "Custom Events with Event Properties",
    Link: "http://braze.looker.com/dashboards/2533",
    Description:
      "Dashboard that provides a breakdown of custom event property usage for companies and/or app groups over a time period. Useful for conversations around paring down attributes tracked, being more specific with goals around custom tracking, and building out user journeys using custom tracking.",
  },
  {
    AssetName: "Data Point Usage",
    DataSource: "Looker Dashboard",
    UseCase: ["Bad Initial Setup", "Leveraging Data In A Meaningful Way", "Inefficient Dashboard Usage"],
    DataType: "Data Points",
    LinkTitle: "Data Point Usage",
    Link: "https://braze.looker.com/dashboards/billable_datapoints::data_point_usage",
    Description:
      "Account-level billing Dashboard. Useful for conversations around paring down attributes tracked, being more specific with goals around custom tracking, and building out user journeys using custom tracking. Please find more information here: Billable Data Points",
  },
  {
    AssetName: "Data Point Usage - App Group Level",
    DataSource: "Looker Dashboard",
    UseCase: ["Bad Initial Setup", "Leveraging Data In A Meaningful Way", "Inefficient Dashboard Usage"],
    DataType: "Data Points",
    LinkTitle: "Data Point Usage - App Group Level",
    Link: "https://braze.looker.com/dashboards/billable_datapoints%3A%3Adata_point_usage_app_group_level",
    Description:
      "App group-level billing Dashboard. Useful for conversations around paring down attributes tracked, being more specific with goals around custom tracking, and building out user journeys using custom tracking. Please find more information here: Billable Data Points.",
  },
  {
    AssetName: "Email Aggregate Dashboards",
    DataSource: "Looker Dashboard",
    UseCase: ["Suffering Business", "Bad Initial Setup", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Email Aggregate Report",
    Link: "https://confluence.braze.com/display/GROW/Email+Aggregate+Report",
    Description:
      "Presents the email data tracked within the Braze platform's Postgres data. By using the Postgres data, the resulting data is EPS independent, so it reflects the data track by Braze, and not a specific ESP.",
  },
  {
    AssetName: "Industry Insight Dashboards",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Industry Insight Dashboards",
    Link: "https://braze.looker.com/folders/1491",
    Description:
      "These Dashboards are meant to provide rich data-led insights on how your account is performing. Each Dashboard has been customized to show the measures that are most relevant to the given industry (there is also a catch-all industry Dashboard). The information from this dashboard will be a great addition to an EBR, strategic meeting, for any engagement recommendations you are giving, or for proactive outreach. Post in the industry insights looker channel if you have any questions.",
  },
  {
    AssetName: "Message Frequency",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Message Frequency",
    Link: "https://braze.looker.com/dashboards/campaigns_model_full%3A%3Amessage_frequency",
    Description:
      "Provides very high-level insight on how many messages per month, per relevant channel, results in the best engagement. Explore from the tiles to get more granular with channels and conversion criteria and to filter on a specific company. This Dashboard may not suit your needs if you need user-level data. The methodology is as follows: the Dashboard takes a given month of messages for a company or group of companies, divides the total messages sent that month by the total unique message recipients within that month, and then calculates the aggregate average engagement rate for that month. Please find methodology details here.",
  },
  {
    AssetName: "Quarterly Renewals",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Partner Opportunities",
    ],
    DataType: "Renewal Opportunities",
    LinkTitle: "Quarterly Renewals",
    Link: "https://braze.looker.com/dashboards/customer_success%3A%3Aquarterly_renewals",
    Description:
      "This Dashboard provides information around renewal and upsell opportunities for existing customers, with the option to filter on CSM name or Account Name. This will give CSMs an idea of the landscape they are working with for a given client. Please do not use the filters that are not relevant for your account or client use case.",
  },
  {
    AssetName: "Retention Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Prioritizing New Features & Tools",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Establish Braze as strategic advisors",
      "Partner Opportunities",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Retention Dashboard",
    Link: "https://braze.looker.com/dashboards/campaigns_model_full%3A%3Aretention_dashboard",
    Description:
      "Provides daily retention and user acquisition metrics that benchmark an app group or list of app groups against an industry.",
  },
  {
    AssetName: "Sendgrid - Advanced Stats",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sendgrid - Advanced Stats",
    Link: "https://braze.looker.com/dashboards/sendgrid_stats%3A%3Asendgrid__advanced_stats",
    Description:
      "Deliverability metrics pulled for Sendgrid email customers, including send, open, and delivery rates. Includes granular information like country, client, and browser.",
  },
  {
    AssetName: "Sendgrid - Braze Deliverability Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sendgrid - Braze Deliverability Dashboard",
    Link: "https://braze.looker.com/dashboards/sendgrid_stats%3A%3Asendgrid__braze_deliverability_dashboard",
    Description:
      "Deliverability metrics pulled for Sendgrid email customers, including send, open, and delivery rates. High-level metrics only, useful for trend identification and analysis.",
  },
  {
    AssetName: "SendGrid - Subuser Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "SendGrid - Subuser Dashboard",
    Link: "https://braze.looker.com/dashboards/sendgrid_stats%3A%3Asendgrid__subuser_dashboard",
    Description:
      "Deliverability metrics pulled for Sendgrid email customers, including send, open, and delivery rates. Displayed by subuser.",
  },
  {
    AssetName: "Sparkpost - Braze Deliverability Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sparkpost - Braze Deliverability Dashboard",
    Link: "https://braze.looker.com/dashboards/sparkpost%3A%3Asparkpost__braze_deliverability_dashboard",
    Description:
      "Deliverability metrics pulled for Sparkpost email customers, including send, open, and delivery rates. High-level metrics only, useful for trend identification and analysis.",
  },
  {
    AssetName: "Sparkpost - SubDomain and IP Dashboard",
    DataSource: "Looker Dashboard",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sparkpost - SubDomain and IP Dashboard",
    Link: "https://braze.looker.com/dashboards/sparkpost%3A%3Asparkpost__subdomain_and_ip_dashboard",
    Description:
      "Deliverability metrics pulled for Sparkpost email customers, including send, open, and delivery rates by sending domain and IP.",
  },
  {
    AssetName: "Benchmarks 2.0 Company Comparison",
    DataSource: "Looker Dashboard",
    UseCase: [
      "Quantifying Market Value",
      "Prioritizing New Features & Tools",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Benchmarks 2.0 Company Comparison",
    Link: "https://braze.looker.com/dashboards/block_braze_benchmarks_2_0%3A%3Abenchmarks_2_0__company_comparison",
    Description: "Compares a given company to the Benchmarks 2.0 metrics for their industry.",
  },
  {
    AssetName: "Daily Stats",
    DataSource: "Looker Explore",
    UseCase: ["Quantifying Market Value", "Expanding the Relationship", "Establish Braze as strategic advisors"],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Daily Stats",
    Link: "https://braze.looker.com/explore/campaigns_model_full/daily_stats_final_full",
    Description:
      "Displays engagement metrics like email unique open rate or conversion rates, broken down by day, company, industry, conversion criteria and more. Also includes total message volume broken down to the day (week, month, quarter, year, etc), information on which companies correspond to which industries, etc.",
  },
  {
    AssetName: "Sendgrid Stats",
    DataSource: "Looker Explore",
    UseCase: ["Suffering Business", "Bad Initial Setup", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sendgrid Stats",
    Link: "https://braze.looker.com/explore/sendgrid_stats/sendgrid_stats_unique",
    Description:
      "Exports of SendGrid deliverability metrics. Exports will be ran daily for the previous day's activity.",
  },
  {
    AssetName: "Usage Analytics",
    DataSource: "Looker Explore",
    UseCase: ["Expanding the Relationship", "Partner Opportunities", "Establish Braze as strategic advisors"],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Usage Analytics",
    Link: "https://braze.looker.com/explore/campaigns_model_full/app_group_app_ds",
    Description: "MAU, MUV, total user profile counts, and stickiness (DAU/MAU) metrics by app or app group.",
  },
  {
    AssetName: "Sparkpost Domain Metrics",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sparkpost Domain Metrics",
    Link: "https://braze.looker.com/explore/sparkpost/sparkpost_domain_metrics",
    Description:
      "Deliverability metrics pulled for Sparkpost email customers, including send, open, and delivery rates. Includes domain information.",
  },
  {
    AssetName: "Custom Attributes",
    DataSource: "Looker Explore",
    UseCase: [
      "Bad Initial Setup",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "Custom Data",
    LinkTitle: "Custom Attributes",
    Link: "https://braze.looker.com/explore/data_points/custom_attributes",
    Description:
      "Metrics for app usage of custom attributes by name, source, type. Can be used to analyze company/app group/app/campaign custom attribute usage.",
  },
  {
    AssetName: "Custom Events",
    DataSource: "Looker Explore",
    UseCase: [
      "Bad Initial Setup",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "Custom Data",
    LinkTitle: "Custom Events",
    Link: "https://braze.looker.com/explore/data_points/custom_events",
    Description:
      "Information around custom events, data points accrued per event, and how each event is used in the platform (campaigns/canvas/segments).",
  },
  {
    AssetName: "SendGrid Geographic Statistics",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "SendGrid Geographic Statistics",
    Link: "https://braze.looker.com/explore/sendgrid_stats/sendgrid_geo_unique",
    Description: "Click and open metrics pulled for Sendgrid email customer by geographic location.",
  },
  {
    AssetName: "SendGrid Browser Stats",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "SendGrid Browser Stats",
    Link: "https://braze.looker.com/explore/sendgrid_stats/sendgrid_browser_unique",
    Description: "Click metrics pulled for Sendgrid email customers by browser.",
  },
  {
    AssetName: "SendGrid Client Stats",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "SendGrid Client Stats",
    Link: "https://braze.looker.com/explore/sendgrid_stats/sendgrid_client_unique",
    Description: "Open metrics pulled for Sendgrid email customers by client.",
  },
  {
    AssetName: "SendGrid Device Stats",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "SendGrid Device Stats",
    Link: "https://braze.looker.com/explore/sendgrid_stats/sendgrid_device_unique",
    Description: "Open metrics pulled for Sendgrid email customers by device.",
  },
  {
    AssetName: "Sparkpost Sending Domain Metrics",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sparkpost Sending Domain Metrics",
    Link: "https://braze.looker.com/explore/sparkpost/sparkpost_sending_domain_metrics",
    Description:
      "Deliverability metrics pulled for Sendgrid email customers, including send, open, and delivery rates. Includes sending domain information.",
  },
  {
    AssetName: "Sparkpost Sending IP Metrics",
    DataSource: "Looker Explore",
    UseCase: ["Inefficient Dashboard Usage", "Suffering Business", "Establish Braze as strategic advisors"],
    DataType: "Email & Email Deliverability",
    LinkTitle: "Sparkpost Sending IP Metrics",
    Link: "https://braze.looker.com/explore/sparkpost/sparkpost_sending_ip_metrics",
    Description:
      "Deliverability metrics pulled for Sendgrid email customers, including send, open, and delivery rates. Includes sending IP information.",
  },
  {
    AssetName: "Daily Retention",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Daily Retention",
    Link: "https://braze.looker.com/explore/campaigns_model_full/daily_retention_full",
    Description:
      "Daily unique user acquisition and classic retention trends broken down by app type, cohort (based on the date the user joined), and number of days retained. If a user comes back on day 7, they are counted on day 7 only--this is the daily/classic retention methodology. Retention rate is calculated as day n / day 0 users.",
  },
  {
    AssetName: "Weekly Retention",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Weekly Retention",
    Link: "https://braze.looker.com/explore/campaigns_model_full/weekly_retention_full",
    Description:
      "Weekly unique user acquisition and classic retention trends broken down by app type, cohort (based on the date the user joined), and number of weeks retained. If a user comes back on week 4, they are counted on week 4 only--this is the weekly/classic retention methodology. Retention rate is calculated as week n / week 0 users.",
  },
  {
    AssetName: "Monthly Retention",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Monthly Retention",
    Link: "https://braze.looker.com/explore/campaigns_model_full/monthly_retention_full",
    Description:
      "Monthly unique user acquisition and classic retention trends broken down by app type, cohort (based on the date the user joined), and number of months retained. If a user comes back during month 2, they are counted for month 2 only--this is the monthly/classic retention methodology. Retention rate is calculated as month n / month 0 users.",
  },
  {
    AssetName: "Purchase Retention",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Purchase Retention",
    Link: "https://braze.looker.com/explore/campaigns_model_full/purchase_retention_full",
    Description:
      "6-month purchase retention trends broken down by app type, cohort (based on the date of the user’s first purchase), segment, company, industry, etc.",
  },
  {
    AssetName: "Session Activity",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Session Activity",
    Link: "https://braze.looker.com/explore/campaigns_model_full/session_activity_full",
    Description:
      "Daily, weekly, and monthly app usage trends, calculated per daily/weekly/monthly unique user and aggregated by company, app type, industry and more",
  },
  {
    AssetName: "Purchase Activity",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Purchase Activity",
    Link: "https://braze.looker.com/explore/campaigns_model_full/purchase_activity_full",
    Description:
      "Daily, weekly, and monthly purchasing behavior trends, calculated per daily/weekly/monthly unique user and aggregated by company, app type, industry and more",
  },
  {
    AssetName: "Time to Purchase",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Time to Purchase",
    Link: "https://braze.looker.com/explore/campaigns_model_full/time_to_purchase_full",
    Description:
      "Tracks likelihood and rapidity of purchase by first session date/first purchase date/second purchase date, as well as company, app type, industry and more",
  },
  {
    AssetName: "Message Frequency",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Message Frequency",
    Link: "https://braze.looker.com/explore/campaigns_model_full/message_frequency_full",
    Description:
      "Provides very high-level insight on how many messages per month, per relevant channel, results in the best engagement. Explore from the tiles to get more granular with channels and conversion criteria and to filter on a specific company. This Explore may not suit your needs if you need user-level data. The methodology is as follows: the Explore takes a given month of messages for a company or group of companies, divides the total messages sent that month by the total unique message recipients within that month, and then calculates the aggregate average engagement rate for that month. Please find methodology details here.",
  },
  {
    AssetName: "Billable Datapoints Daily Stats",
    DataSource: "Looker Explore",
    UseCase: ["Bad Initial Setup", "Leveraging Data In A Meaningful Way", "Inefficient Dashboard Usage"],
    DataType: "Data Points",
    LinkTitle: "Billable Datapoints Daily Stats",
    Link: "https://braze.looker.com/explore/billable_datapoints/dp_daily_view",
    Description:
      "This Explore gives Braze employees the ability to explore clients' data point usage pacing in relation to their contract allotment. The insights can provide details on data point type and source.",
  },
  {
    AssetName: "Net Promoter Score",
    DataSource: "Looker Explore",
    UseCase: ["Endless Product Requests", "Shifting org", "Difficulty with Braze Support"],
    DataType: "Net Promoter Score",
    LinkTitle: "Net Promoter Score",
    Link: "https://braze.looker.com/explore/net_promoter_score/net_promoter_score",
    Description:
      "Provides metrics for customers about their net promoter scores - scoring can be broken down by various parameters.",
  },
  {
    AssetName: "Support Cases",
    DataSource: "Looker Explore",
    UseCase: ["Difficulty with Braze support"],
    DataType: "Support Cases",
    LinkTitle: "Support Cases",
    Link: "https://braze.looker.com/explore/customer_success/support_cases",
    Description:
      "View historical support cases and metadata around each case (such as type, resolution date, priority, etc). This will help you lead a ticket retro.",
  },
  {
    AssetName: "Custom Events",
    DataSource: "Looker Explore",
    UseCase: ["Endless Product Requests", "Shifting org", "Difficulty with Braze Support"],
    DataType: "Braze Dashboard Usage",
    LinkTitle: "Custom Events",
    Link: "https://braze.looker.com/explore/customer_behavior_events/users_behaviors_customevent",
    Description:
      "This Explore will give detailed information on custom event behavior from marketers/developers/users of the Braze platform. The Product team uses this Explore to track specific actions being taken in Braze, such as a user creating a campaign or Canvas. This may be useful for a CSM to locate where pain points exist, pinpoint room for growth, and showcase great product adoption.",
  },
  {
    AssetName: "Skilljar",
    DataSource: "Looker Explore",
    UseCase: ["Endless Product Requests", "Shifting org", "Difficulty with Braze Support"],
    DataType: "LAB/Skilljar",
    LinkTitle: "Skilljar",
    Link: "https://braze.looker.com/explore/skilljar/skilljar",
    Description:
      "This Explore should primarily be used to view Skilljar course adoption on a company level. This information will help you understand the level of product knowledge within your client's dashboard user base has, the spread of that knowledge, and which areas of the product they may not be familiar with.",
  },
  {
    AssetName: "Skilljar User Behavior",
    DataSource: "Looker Explore",
    UseCase: ["Endless Product Requests", "Shifting org", "Difficulty with Braze Support"],
    DataType: "LAB/Skilljar",
    LinkTitle: "Skilljar User Behavior",
    Link: "https://braze.looker.com/explore/skilljar/skilljar_user_behavior",
    Description:
      "This Explore should primarily be used to view Skilljar course adoption on a user level. That information will help you understand who to liaise with, the percent of users active in the Dashboard which are actively learning about the Braze product within LAB, and teaching opportunities for those in the room on advanced concepts like connected content.",
  },
  {
    AssetName: "Campaign Copy & Creative",
    DataSource: "Looker Explore",
    UseCase: [
      "Quantifying Market Value",
      "Prioritizing New Features & Tools",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Establish Braze as strategic advisors",
      "Leverage data in a more meaningful way",
      "Build Out User Journeys",
      "Cross channel marketing 101 (best practices)",
    ],
    DataType: "Campaigns/Canvas and Messaging",
    LinkTitle: "Campaign Copy & Creative",
    Link: "https://braze.looker.com/explore/industry_solutions/campaign_copy_and_creative",
    Description:
      "This Explore breaks down messaging to the variant level. This is where you can view actual message copy and feature adoption, as well as view conversion rates by specific channel received or compare specific channels against control variants. You can filter on a company or industry.",
  },
  {
    AssetName: "Messaging Frequency",
    DataSource: "Data Extract",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Messaging Frequency",
    Link: `${"/data-extract?type_of_data=Messaging%20Frequency"}`,
    Description:
      'Messaging frequency plots the number of messages a user received over a given time period, and what the corresponding open rates would be at that level.  This extract is used by customers to optimize sending frequency, to see how much is "too much" when it comes to messaging users. This extract pulls the total messages sent over the time period, as well as weekly and monthly frequency data.',
  },
  {
    AssetName: "Opt-in Rates",
    DataSource: "Data Extract",
    UseCase: [
      "Bad Initial Setup",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Leveraging Data In A Meaningful Way",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Opt-in Rates",
    Link: `${"data-extract?type_of_data=Opt-in%20Rates"}`,
    Description:
      'An opt-in rate is a percentage of a device or user base that has allowed a sender to send them messages ("opted in").  Often this is done via an explicit prompt from the sender, but different types of opt-in will have different evaluation criteria. Opt-in rates will be delivered for a trailing 12-month period of time, using a user\'s first session month date for the time dimension.',
  },
  {
    AssetName: "Custom Event A/B Retention",
    DataSource: "Data Extract",
    UseCase: [
      "Quantifying Market Value",
      "Shift in how client views Braze",
      "Channel Expansion (Web, Content Cards, Email, SMS)",
      "Expanding the Relationship",
      "Build Out User Journeys",
      "Establish Braze as strategic advisors",
    ],
    DataType: "App Engagement, Retention, and Purchase Activity",
    LinkTitle: "Custom Event A/B Retention",
    Link: `${"data-extract?type_of_data=Custom%20Event%20A%2FB%20Retention"}`,
    Description:
      "Retention is a measure of how long a user stays engaged with an app or customer. Custom Event A/B retention outputs retention metrics for users who have performed specific custom events.",
  },
];
