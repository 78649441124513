import { Button, CopyField, FieldLabel, Modal, ModalBody, ModalFooter, ModalHeading } from "@braze/beacon-core";
import QRCode from "qrcode.react";
import React from "react";
import { QRCodeContainer, QRCodeModalBody } from "./styles";

type QRCodeModalProps = {
  url: string;
  isOpen: boolean;
  toggle: () => void;
};

export const QRCodeModal = ({ url, isOpen, toggle }: QRCodeModalProps) => (
  <Modal size="sm" isOpen={isOpen} toggle={toggle}>
    <ModalHeading>Demo Link</ModalHeading>
    <ModalBody>
      <QRCodeModalBody>
        <p>Users can activate this demo either by following the link below or scanning the QR code.</p>
        <FieldLabel>
          <CopyField value={url} showTextInButton vSize="md" />
        </FieldLabel>
        <QRCodeContainer>
          <QRCode value={url} width={375} size={375} bgColor="#ffffff" fgColor="#000000" level="M" />
        </QRCodeContainer>
      </QRCodeModalBody>
    </ModalBody>
    <ModalFooter>
      <ModalFooter aligner side="right">
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </ModalFooter>
  </Modal>
);
