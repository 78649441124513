import * as _ from "lodash";
import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";

import { CompanyResult } from "src/hooks/types";
import { OptionsLabelValue } from "src/components/types";
import styled from "styled-components";
import { useGetAccountsWithType } from "src/hooks/useGetAccountsWithType";

const TIMEOUT_VAL = 500;

const StyledWarningText = styled.div`
  color: red;
  font-size: 12.5px;
  margin-top: -0.15rem;
  margin-bottom: 0.62rem;
  display: flex;
  align-items: center;

  img {
    margin-right: 6px;
    padding-bottom: 2px;
  }
`;

type CompanySearchSelectProps = {
  onSelectCompany: (selectedCompany?: CompanyResult) => void;
  accountTypes?: string[];
};

export const CompanySearchSelect = ({ onSelectCompany, accountTypes }: CompanySearchSelectProps) => {
  const [companyOptions, setCompanyOptions] = useState<OptionsLabelValue[]>([]);

  const { value, error, loading, refetch } = useGetAccountsWithType(true, accountTypes || []);

  // refetch companies with debounce
  const fetchCompanies = _.debounce((companyPrefix) => {
    if (companyPrefix) {
      refetch({
        name: companyPrefix,
        accountTypes: accountTypes ? JSON.stringify(accountTypes) : JSON.stringify([]), // must include accountTypes in refetch too.
      });
    }
  }, TIMEOUT_VAL);

  useEffect(() => {
    const res = (value || []).map((company: CompanyResult) => ({
      label: company.ACCOUNT_NAME,
      value: company,
    }));

    setCompanyOptions(res);
  }, [value]);

  return (
    <>
      {!!error && (
        <StyledWarningText>
          <img src="public/img/warning.svg" alt="warning icon" />
          Cannot connect to database.
        </StyledWarningText>
      )}
      <Select
        inputId="account-name"
        isClearable
        isDisabled={false}
        isSearchable
        fluid
        isLoading={loading}
        backspaceRemovesValue
        onMenuClose={() => {
          setCompanyOptions([]);
        }}
        options={companyOptions}
        menuPortalTarget={document.body}
        placeholder="Type in to see results..."
        onInputChange={fetchCompanies}
        onChange={(selectedOption) => {
          if (selectedOption) {
            onSelectCompany(selectedOption.value);
          } else {
            onSelectCompany(undefined);
          }
        }}
      />
    </>
  );
};
