import { Select } from "@braze/beacon-lab-select";
import { useState } from "react";
import { OptionsLabelValue } from "src/components/types";
import { SandboxFreeTrialEnvironmentType } from "src/hooks/types";

const environmentTypes: SandboxFreeTrialEnvironmentType[] = ["07", "09", "sweeney", "olaf"];

export const EnvironmentOptions = environmentTypes.map((envType) => ({
  label: envType,
  value: envType,
}));

export type EnvironmentSelectProps = {
  onEnvironmentSelect: (selectedEnvironment: OptionsLabelValue | null) => void;
};

export const EnvironmentSelect = ({ onEnvironmentSelect }: EnvironmentSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionsLabelValue | null>(null);

  const handleChange = (selectedOption: OptionsLabelValue | null) => {
    setSelectedOption(selectedOption); // This will set the state to null if the selection is cleared
    onEnvironmentSelect(selectedOption); // This ensures that when the select is cleared, the callback is called with an object that has an empty label and null value
  };
  return (
    <Select
      inputId="environment-select"
      isClearable
      isDisabled={false}
      isSearchable
      fluid
      options={EnvironmentOptions}
      menuPortalTarget={document.body}
      value={selectedOption}
      placeholder="Select an environment..."
      onChange={handleChange}
    />
  );
};
