export const RequirementsGuide = "https://confluence.braze.com/display/GROW/Analytics+for+Individual+Customers";

export enum RequestReason {
  EBR = "EBR",
  Sales = "Sales Opportunity",
  ISR = "ISR",
  Other = "Other",
}

export enum Analysis {
  Retrieval = "Customer Data Retrieval [CS VPs only]",
  Insights = "Customer Insights Exploration [CS VPs only]",
  Consulting = "Strategic Consulting Request",
}

export const RequestReasonOptions = [
  {
    value: RequestReason.EBR,
    label: RequestReason.EBR,
  },
  {
    value: RequestReason.ISR,
    label: RequestReason.ISR,
  },
  {
    value: RequestReason.Sales,
    label: RequestReason.Sales,
  },
  {
    value: RequestReason.Other,
    label: RequestReason.Other,
  },
];

export const AnalysisOptions = [
  {
    value: Analysis.Consulting,
    label: Analysis.Consulting,
  },
  {
    value: Analysis.Insights,
    label: Analysis.Insights,
  },
  {
    value: Analysis.Retrieval,
    label: Analysis.Retrieval,
  },
];
