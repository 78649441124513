// Constants to represent the display name and redirect names

import { DefaultOptionType } from "@braze/beacon-lab-select";

export const workspaces: DefaultOptionType[] = [
  {
    value: "live",
    label: "Live",
  },
  {
    value: "demo",
    label: "Demo",
  },
];

export const assets: DefaultOptionType[] = [
  {
    value: "campaign",
    label: "Campaign",
  },
  {
    value: "canvas",
    label: "Canvas",
  },
  {
    value: "segment",
    label: "Segment",
  },
  {
    value: "campaign_report",
    label: "Campaign Report",
  },
  {
    value: "canvas_report",
    label: "Canvas Report",
  },
  {
    value: "page",
    label: "Page without an asset",
  },
];
