import { API_BASE_URL } from "src/config";
import { OpportunityResult } from "./types";
import { useFetch } from "./useFetch";

export const useGetOpportunities = (oppFetchDisabled: boolean, accountId: string) =>
  useFetch<OpportunityResult[]>({
    url: `${API_BASE_URL}/api/company/opportunities`,
    lazyload: oppFetchDisabled,
    params: { id: accountId },
  });
