import { usePost } from "src/hooks/usePost";
import { API_BASE_URL } from "src/config";

type EncryptRequestBody = {
  ios: string;
  android: string;
};

type EncryptedResponse = EncryptRequestBody;

export const useEncryptedAPIKey = (): [
  (iosKey: string, androidKey: string) => Promise<EncryptedResponse | null>,
  {
    value?: EncryptedResponse | null;
    loading: boolean;
    called: boolean;
    error?: string | undefined;
    isSuccess: boolean;
  }
] => {
  const [post, result] = usePost<EncryptedResponse, EncryptRequestBody>({
    url: `${API_BASE_URL}/api/encrypt-qr-code-data`,
  });

  const postEncryptKeys = async (iosKey: string, androidKey: string): Promise<EncryptedResponse | null> => {
    const encryptedData = await post({
      ios: iosKey,
      android: androidKey,
    });

    return encryptedData;
  };

  return [postEncryptKeys, result];
};
