import { FieldLabel, HelpText, Input } from "@braze/beacon-core";
import { DefaultOptionType, Select } from "@braze/beacon-lab-select";
import React, { useCallback } from "react";
import { assets, workspaces } from "src/components/DeepLinkGenerator/constants";
import { DeepLinkGeneratorInput } from "src/components/DeepLinkGenerator/styles";
import { BasicDetailsSelectProps } from "src/components/DeepLinkGenerator/BasicDetails/types";

export const BasicDetailsSelect: React.FC<BasicDetailsSelectProps> = ({
  workspace,
  onWorkspaceChanged,
  assetName,
  onAssetNameChanged,
  assetType,
  onAssetTypeChanged,
  url,
  onUrlChanged,
  isAssetNameVisible,
  isUrlVisible,
  extraParams,
  onExtraParamsChanged
}: BasicDetailsSelectProps) => {
  const handleAssetTypeChange = useCallback(
    (newValue: DefaultOptionType) => {
      onAssetTypeChanged(newValue);
    },
    [onAssetTypeChanged]
  );

  const handleWorkspaceChange = useCallback(
    (newValue: DefaultOptionType) => {
      onWorkspaceChanged(newValue);
    },
    [onWorkspaceChanged]
  );

  const handleUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onUrlChanged(e.target.value);
    },
    [onUrlChanged]
  );

  const handleAssetNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onAssetNameChanged(e.target.value);
    },
    [onAssetNameChanged]
  );

  const handleExtraParamsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onExtraParamsChanged(e.target.value);
    },
    [onExtraParamsChanged]
  );

  return (
  <DeepLinkGeneratorInput>
    <FieldLabel
      label="Choose an Item to Create Your Deep Link"
      htmlFor="basic-select"
      enforce="required"
    >
      <Select
        inputId="basic-select"
        value={assetType}
        options={assets}
        onChange={handleAssetTypeChange}
      />
      <HelpText>
        Select an asset for which the deep link will be generated.
      </HelpText>
    </FieldLabel>
    <FieldLabel
      label="Select a Workspace"
      htmlFor="basic-select"
      enforce="optional"
    >
      <Select
        inputId="basic-select"
        value={workspace}
        options={workspaces}
        onChange={handleWorkspaceChange}
      />
      <HelpText>
        Select a workspace for which the deep link will be generated.
      </HelpText>
    </FieldLabel>
    {isAssetNameVisible && (
      <FieldLabel label="Asset name" enforce="required">
        <Input
          id="demoNameInput"
          placeholder="Enter asset name"
          value={assetName}
          onChange={handleAssetNameChange}
        />
        <HelpText className="basicDetailsStatusText">
          Copy and paste the name of the asset that you want to link to from the
          dashboard.
        </HelpText>
      </FieldLabel>
    )}
    {isUrlVisible && (
      <FieldLabel label="Dashboard URL" enforce="required">
        <Input
          id="demoNameInput"
          placeholder="https://dashboard-09.braze.com/"
          value={url}
          onChange={handleUrlChange}
        />
        <HelpText className="basicDetailsStatusText">
          Copy and paste the URL of the page that you want to link to from the
          dashboard.
        </HelpText>
      </FieldLabel>
    )}
      <FieldLabel label="Extra Params" enforce="optional">
        <Input
          id="extraParamInput"
          placeholder="Enter extra params"
          value={extraParams}
          onChange={handleExtraParamsChange}
        />
        <HelpText className="basicDetailsStatusText">
          Copy and paste the extra params from the dashboard url or add your own params separated by an ampersand (&).
          For example: param1=value1&param2=value2
        </HelpText>
      </FieldLabel>
  </DeepLinkGeneratorInput>
)};
