import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

type Props = {
  url: string;
  lazyload: boolean;
  params?: Record<string, string | number | boolean>;
};

// useFetch is a lower level generic hook. if React APP is making call to multiple API server such as Algolia, Twilio, Braze API, useFetch can be used to create higher level hook with different API Url.
export const useFetch = <T>(props: Props) => {
  const { url, lazyload } = props;
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  // When component testing, Okta is not initialized and we cannot destructure a null object
  const okta = useOktaAuth();

  const refetch = async (params?: Record<string, string | number | boolean>) => {
    try {
      setLoading(true);
      setValue(undefined);
      setError(undefined);
      const queryString = params
        ? Object.keys(params)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join("&")
        : "";
      const res = await fetch(`${url}?${queryString}`, {
        headers: {
          AUTHORIZATION: okta?.authState?.idToken?.idToken || "",
        },
      });
      const result = await res.json();
      if ("error" in result) {
        throw 400;
      }
      setValue(result);
    } catch (e) {
      setError("API Error Occurred!");
    } finally {
      setLoading(false);
    }
  };

  // If path params are used, refetch should be called if path params get updated. EX. GET /company/:id
  useEffect(() => {
    if (lazyload === false) {
      refetch(props.params);
    }
  }, [url && lazyload]);

  return {
    value,
    loading,
    error,
    refetch,
  };
};
