import { Select } from "@braze/beacon-lab-select";
import { useState } from "react";
import { OptionsLabelValue } from "src/components/types";

export const SandboxTypeOptions = [
  {
    label: "Free Trial",
    value: "free_trial",
  },
  {
    label: "Workshop",
    value: "workshop",
  },
];

export type SandboxTypeSelectProps = {
  onSandboxTypeSelect: (selectedIndustry: OptionsLabelValue | null) => void;
};

export const SandboxTypeSelect = ({ onSandboxTypeSelect }: SandboxTypeSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionsLabelValue | null>(null);

  const handleChange = (selectedOption: OptionsLabelValue | null) => {
    setSelectedOption(selectedOption);
    onSandboxTypeSelect(selectedOption);
  };

  return (
    <Select
      inputId="sandboxType"
      isClearable
      isSearchable
      fluid
      options={SandboxTypeOptions}
      menuPortalTarget={document.body}
      value={selectedOption}
      placeholder="Select a Sandbox type..."
      onChange={handleChange}
    />
  );
};
