import { DefaultOptionType } from "@braze/beacon-lab-select";

export const useGenerateDeepLink = (
  url: string | URL | null,
  assetName: string | null,
  workspace: DefaultOptionType | null,
  assetType: DefaultOptionType | null,
  extraParams: string | null
) => {
  const BRAZE_DASHBOARD_DEEP_LINK_URL = "https://dashboard-09.braze.com/deeplink";
  const deeplink = new URL(BRAZE_DASHBOARD_DEEP_LINK_URL);

  const params = new URLSearchParams();

  if (assetType) {
    params.append("asset_type", assetType.value);
  }

  if (url) {
    params.append("path", replaceIds(url));
  }

  if (workspace) {
    params.append("workspace", workspace.value);
  }

  if (assetName) {
    params.append("asset_name", assetName);
  }

  if (extraParams) {
    params.append("extra_params", extraParams);
  }

  deeplink.search = params.toString();
  return deeplink.toString();
};

const replaceIds = (url: string | URL): string => {
  let pathWithParams: string;

  try {
    // Create a URL object to extract the path
    const urlObj = new URL(url);
    const path = urlObj.pathname; // Extract the path from the URL
    const params = urlObj.search; // Extract the query parameters from the URL

    // Combine the path and query parameters
    pathWithParams = `${path}${params}`;
  } catch (error) {
    // Handle invalid URL error
    console.error("Invalid URL provided:", url);
    alert("Invalid URL provided: " + url);
    return ""; // Return an empty string
  }

  // Regular expression to match the ids
  const idRegex = /[0-9a-f]{24}/gi;

  // Extract the chameleon parameter pattern
  const chameleonRegex = /chmln=c-([0-9a-f]+)/i;
  const chameleonMatch = pathWithParams.match(chameleonRegex);
  const chameleonId = chameleonMatch ? chameleonMatch[1] : null;

  // Find all ids in the path
  const allIds = pathWithParams.match(idRegex) || [];

  // Filter out the chameleon id
  const idsToReplace = allIds.filter((id) => id !== chameleonId);

  if (idsToReplace.length === 2) {
    // Replace first ID with app_group_id and second with asset_id
    return pathWithParams
      .replace(new RegExp(idsToReplace[0], "g"), "app_group_id")
      .replace(new RegExp(idsToReplace[1], "g"), "asset_id");
  } else if (idsToReplace.length === 1) {
    // Replace the single ID with app_group_id
    return pathWithParams.replace(new RegExp(idsToReplace[0], "g"), "app_group_id");
  }

  // If no IDs are found, return the original path
  return pathWithParams;
};
