import { FieldLabel, HelpText, Input } from "@braze/beacon-core";
import React from "react";
import { QRCodeGeneratorInput } from "src/components/QRCodeGenerator/styles";

type DemoNameProps = {
  demoName: string;
  onDemoNameChanged: (name: string) => void;
};
export const DemoName = ({ demoName, onDemoNameChanged }: DemoNameProps) => (
  <QRCodeGeneratorInput>
    <FieldLabel label="Demo Name" enforce="required">
      <Input
        id="demoNameInput"
        placeholder="Type in a value"
        value={demoName}
        onChange={(e) => {
          onDemoNameChanged(e.target.value);
        }}
      />
      <HelpText className="basicDetailsStatusText">
        Pilot will log a custom event started_demo with the property name set to this value.
      </HelpText>
    </FieldLabel>
  </QRCodeGeneratorInput>
);
