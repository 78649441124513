import {
  Button,
  FieldLabel,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeading,
  StatusText,
} from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";
import { useForm } from "src/hooks/useForm";
import { EnvironmentOptions } from "./data";
import { ModalState } from "src/hooks/useModalState";
import { useApiMutation } from "src/hooks/useApi";
import { useState } from "react";
import { EditModalDataType, EditModalFormFieldType, SandboxSubmissionPutResponse } from "./types";
import { SandboxFreeTrialEnvironmentType, UpdateSandBoxSubmissionBody } from "src/hooks/types";
import { convertKeysToSnakeCase } from "./utils";
import { getUserInformation } from "src/utils";

export type ModalStateWithData<T> = Omit<ModalState<T>, "data"> & {
  data: T; // This makes 'data' required
};

export const EditModal = ({ isOpen, toggle, onSubmit, data }: ModalStateWithData<EditModalDataType>) => {
  const { formState, setFormFieldValue, isTouched } = useForm<EditModalFormFieldType>({
    sandboxCompanyId: data.sandboxCompanyId ?? undefined,
    sandboxApiKey: data.sandboxApiKey ?? undefined,
    environment: data.environment ?? undefined,
    sandboxServiceOrigin: data.sandboxServiceOrigin ?? undefined,
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const submissionId = data.id;

  const { sandboxCompanyId, sandboxApiKey, environment, sandboxServiceOrigin } = formState;

  const mutation = useApiMutation<
    SandboxSubmissionPutResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    UpdateSandBoxSubmissionBody
  >("PUT", `/sandbox/submission/${submissionId}`);
  const userInfo = getUserInformation();

  const updateData = async () => {
    if (isTouched && data && !mutation.isLoading) {
      setErrorMessage(undefined);
      const updatedData: Partial<EditModalDataType> = {
        id: submissionId,
        requester: userInfo?.email,
      };

      Object.entries(formState).forEach(([key, value]) => {
        if (value !== data[key as keyof EditModalFormFieldType]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updatedData[key as keyof EditModalFormFieldType] = value as any;
        }
      });

      const updatedDataSnakeCase: UpdateSandBoxSubmissionBody = convertKeysToSnakeCase(updatedData);
      try {
        await mutation.mutateAsync(updatedDataSnakeCase);
        if (onSubmit) {
          onSubmit(updatedData as EditModalDataType);
        }
        toggle();
      } catch (error) {
        console.error(error);
        setErrorMessage("An error occurred while submitting the form. Please try again.");
      }
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeading>Edit Data</ModalHeading>
      <ModalBody>
        {errorMessage && (
          <StatusText displayIcon status="danger">
            {errorMessage}
          </StatusText>
        )}
        <FieldLabel htmlFor="dashboardWorkspaceID" label="Dashboard Workspace ID">
          <Input
            id="dashboardWorkspaceID"
            value={sandboxCompanyId}
            onChange={(e) => setFormFieldValue("sandboxCompanyId", e.target.value)}
          />
        </FieldLabel>
        <FieldLabel htmlFor="scimAPIKey" label="SCIM API Key">
          <Input
            id="scimAPIKey"
            value={sandboxApiKey}
            onChange={(e) => setFormFieldValue("sandboxApiKey", e.target.value)}
          />
        </FieldLabel>
        <FieldLabel htmlFor="environment" label="Environment">
          <Select
            zIndex={8000}
            inputId="environment"
            isClearable={false}
            isSearchable
            menuPortalTarget={document.body}
            options={EnvironmentOptions}
            onChange={(selectedOption) => {
              setFormFieldValue("environment", selectedOption?.value as SandboxFreeTrialEnvironmentType);
            }}
            value={EnvironmentOptions.find((o) => o.value === environment)}
          />
        </FieldLabel>
        <FieldLabel htmlFor="sandboxServiceOrigin" label="Sandbox Service Origin">
          <Input
            id="sandboxServiceOrigin"
            value={sandboxServiceOrigin}
            onChange={(e) => setFormFieldValue("sandboxServiceOrigin", e.target.value)}
          />
        </FieldLabel>
      </ModalBody>
      <ModalFooter>
        <ModalFooter aligner side="left">
          <Button
            onClick={updateData}
            disabled={!isTouched || mutation.isLoading}
            loadingState={mutation.isLoading ? "loading" : "idle"}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalFooter>
    </Modal>
  );
};

export default EditModal;
