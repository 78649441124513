import React from "react";
import { BasicDetailsSelect } from "./BasicDetailsSelect/BasicDetailsSelect";
import { Box, Heading } from "@braze/beacon-core";
import { DeepLinkGeneratorSection } from "src/components/DeepLinkGenerator/styles";
import { BasicDetailsSelectProps } from "./types";

export const BasicDetails: React.FC<BasicDetailsSelectProps> = (props) => (
  <Box>
    <Heading level={3}>Basic Details</Heading>
    <DeepLinkGeneratorSection>
      <BasicDetailsSelect {...props} />
    </DeepLinkGeneratorSection>
  </Box>
);
