import { useEffect, useState } from "react";
import { Select } from "@braze/beacon-lab-select";

import { CompanyResult, ContactResult } from "src/hooks/types";
import { useGetContacts } from "src/hooks/useGetContacts";
import { OptionsLabelValue } from "src/components/types";

export type Contact = {
  name: string;
  email: string;
};

type ContactSelectProps = {
  onContactSelect: (contacts: Contact[]) => void;
  selectedCompany?: CompanyResult;
  isMulti?: boolean;
  inModal?: boolean;
  filteredContactEmails?: string[];
};

export const ContactSelect = ({
  onContactSelect,
  selectedCompany,
  isMulti,
  inModal,
  filteredContactEmails,
}: ContactSelectProps) => {
  const [contactOptions, setContactOptions] = useState<OptionsLabelValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsLabelValue[]>([]);

  const { value, loading } = useGetContacts(!selectedCompany, selectedCompany?.ACCOUNT_ID ?? "");

  useEffect(() => {
    if (value) {
      const res = (value || [])
        .filter((c) => (filteredContactEmails ? !filteredContactEmails.includes(c.CONTACT_EMAIL) : true))
        .map((cont: ContactResult) => ({
          label: cont.CONTACT_NAME,
          description: cont.CONTACT_EMAIL,
          value: {
            name: cont.CONTACT_NAME,
            email: cont.CONTACT_EMAIL,
          },
        }));
      setContactOptions(res);
    }
  }, [value, filteredContactEmails]);

  useEffect(() => {
    setSelectedOptions([]);
    onContactSelect([]);
    if (selectedCompany?.CONTACT === undefined) {
      setContactOptions([]);
    } else {
      if (selectedCompany.CONTACT) {
        const contact = contactOptions.find((c) => c.value === selectedCompany.CONTACT);
        if (contact) {
          setSelectedOptions([
            {
              label: contact.label,
              value: contact.value,
            },
            ...selectedOptions,
          ]);
        }
      }
    }
  }, [selectedCompany?.CONTACT]);

  const isMultiple = isMulti === undefined ? true : isMulti;

  useEffect(() => {
    if (!isMultiple) {
      onContactSelect(selectedOptions.length ? [selectedOptions[0].value] : []);
    } else {
      const options = (selectedOptions || []).map((selectedOption: OptionsLabelValue) => selectedOption.value);
      onContactSelect(options);
    }
  }, [isMultiple, selectedOptions]);

  return (
    <Select
      inputId="contacts"
      isMulti={isMultiple}
      isClearable
      isDisabled={!selectedCompany}
      isSearchable
      fluid
      options={contactOptions}
      menuPortalTarget={inModal ? (document.getElementById("dialog-base") as HTMLElement) : document.body}
      value={selectedOptions}
      isLoading={loading}
      placeholder="Type in to see results..."
      onChange={(selectedOptions) => {
        setSelectedOptions(selectedOptions);
      }}
      menuPosition="fixed"
    />
  );
};
