import { BasicDetails } from "src/components/DeepLinkGenerator/BasicDetails";
import { Divider } from "src/components/QRCodeGenerator/Divider";
import { GenerateSection } from "src/components/DeepLinkGenerator/GenerateSection";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import { FormPanelBodyFull, RequestForm } from "src/components/commonStyles";
import {
  DeepLinkGeneratorBody,
  DeepLinkGeneratorView,
} from "src/pages/deeplink-generator/styles";
import { Box, CopyField, FieldLabel, Heading } from "@braze/beacon-core";
import { useDeepLinkGenerator } from "src/hooks/useDeepLink/useDeepLinkGenerator";
import { useValidation } from "src/hooks/useDeepLink/useValidation";
import { useGenerateDeepLink } from "src/hooks/useDeepLink/useGenerateDeepLink";
import { useCallback } from "react";

export const DeepLinkGenerator = () => {
  const {
    currentUrl,
    url,
    assetName,
    workspace,
    assetType,
    isAssetNameVisible,
    isUrlVisible,
    isDeepLinkVisible,
    extraParams,
    onSetWorkspace,
    onSetAssetType,
    onReset,
    onSetCurrentUrl,
    onSetUrl,
    onSetAssetName,
    setIsDeepLinkVisible,
    onSetExtraParams,
  } = useDeepLinkGenerator();

  const validateInputs = useValidation(url, assetName, workspace, assetType, extraParams);

  const onGenerateButtonPressed = useCallback(async () => {
    if (!validateInputs.isValid) {
      alert(
        `Please fill in the following required fields before generating the deep link: ${validateInputs.invalidInputs}`
      );
      return;
    }
    try {
      const deeplink = await useGenerateDeepLink(url, assetName, workspace, assetType, extraParams);
      
      // Check if deeplink is valid before proceeding
      if (!deeplink) {
        throw new Error("Failed to generate a valid deep link.");
      }

      onSetCurrentUrl(deeplink.toString());
      setIsDeepLinkVisible(true);
    } catch (error) {
      console.error("Error generating deep link:", error);
      alert("An error occurred while generating the deep link. Please try again.");
    }
  }, [
    url,
    assetName,
    workspace,
    assetType,
    validateInputs,
    onSetCurrentUrl,
    setIsDeepLinkVisible,
  ]);

  return (
    <Box>
      <NavigateBackButton />
      <RequestForm>
        <FormPanelBodyFull>
          <DeepLinkGeneratorView>
            <Heading level={2}>Generate Free Trial Deep Link</Heading>
            <DeepLinkGeneratorBody>
              <BasicDetails
                url={url}
                workspace={workspace}
                onWorkspaceChanged={onSetWorkspace}
                onUrlChanged={onSetUrl}
                assetName={assetName}
                assetType={assetType}
                onAssetNameChanged={onSetAssetName}
                onAssetTypeChanged={onSetAssetType}
                isAssetNameVisible={isAssetNameVisible}
                isUrlVisible={isUrlVisible}
                extraParams={extraParams}
                onExtraParamsChanged={onSetExtraParams}
              />
              <Divider />
              {isDeepLinkVisible && (
                <>
                  <FieldLabel>
                    <CopyField value={currentUrl} showTextInButton vSize="md"  style={{ width: '100%'}} />
                  </FieldLabel>
                  <Divider />
                </>
              )}
              <GenerateSection
                onGenerateButtonPressed={onGenerateButtonPressed}
                onResetButtonPressed={onReset}
              />
            </DeepLinkGeneratorBody>
          </DeepLinkGeneratorView>
        </FormPanelBodyFull>
      </RequestForm>
    </Box>
  );
};
