import { Button, Icon } from "@braze/beacon-core";
import React, { useCallback, useState } from "react";
import { useLoadingButton } from "@braze/beacon-hooks";
import type { ButtonLoadingState } from "@braze/beacon-hooks";
import { Flex } from "@braze/beacon-core";

type GenerateButtonProps = {
  onGenerateButtonPressed: () => void;
};

export const GenerateButton: React.FC<GenerateButtonProps> = ({
  onGenerateButtonPressed,
}) => {
  const [status, setStatus] = useState<ButtonLoadingState>("idle");
  
  const handleClick = useCallback(() => {
    setStatus("loading");
    onGenerateButtonPressed();
    setTimeout(() => {
      setStatus("success");
    }, 300); 
  }, [onGenerateButtonPressed]);

  const [buttonProps] = useLoadingButton(status);

  return (
    <Flex justifyContent="space-around" className="buttons">
      <Button
        {...buttonProps}
        loadingMessage="Generating..."
        onClick={handleClick}
      >
        {status === "loading" ? (
          <span>Generating...</span>
        ) : (
          <>
            <span>Generate Link</span>
            <Icon name="link" />
          </>
        )}
      </Button>
    </Flex>
  );
};
