import { Select } from "@braze/beacon-lab-select";
import { useState } from "react";
import { OptionsLabelValue } from "src/components/types";

export const IndustryOptions = [
  {
    label: "agnostic",
    value: "agnostic",
  },
  {
    label: "ecommerce",
    value: "ecommerce",
  },
  {
    label: "fintech",
    value: "fintech",
  },
  {
    label: "healthcare",
    value: "healthcare",
  },
  {
    label: "other",
    value: "other",
  },
];

export type IndustrySelectProps = {
  onIndustrySelect: (selectedIndustry: OptionsLabelValue | null) => void;
};

export const IndustrySelect = ({ onIndustrySelect }: IndustrySelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionsLabelValue | null>(null);

  const handleChange = (selectedOption: OptionsLabelValue | null) => {
    setSelectedOption(selectedOption);
    onIndustrySelect(selectedOption);
  };

  return (
    <Select
      inputId="industry"
      isClearable
      isDisabled={false}
      isSearchable
      fluid
      options={IndustryOptions}
      menuPortalTarget={document.body}
      value={selectedOption}
      placeholder="Select an industry..."
      onChange={handleChange}
    />
  );
};
