import styled from "styled-components";
import { Box } from "@braze/beacon-core";

export const QRCodeGeneratorView = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const QRCodeGeneratorBody = styled(Box)`
  margin-top: 50px;
`;
