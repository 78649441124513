import { EmailRow, FailureLabel, SuccessLabel } from "./styles";

export const EmailItem = ({ email, status, last }: { email: string; status?: string; last?: boolean }) => (
  <EmailRow last={last}>
    <span>{email}</span>
    {status && (
      <>{status === "Success" ? <SuccessLabel>{status}</SuccessLabel> : <FailureLabel>{status}</FailureLabel>}</>
    )}
  </EmailRow>
);
