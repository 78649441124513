import { Box, FlexBox } from "@braze/beacon-core";
import styled from "styled-components";

export const CreateUserSectionBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const CreateUserSectionItem = styled(FlexBox)`
  align-items: flex-start;
  width: 280px;
  flex-wrap: wrap;
  margin-right: 25px;
`;

export const CreateUserSectionItemSwitchBox = styled(FlexBox)`
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
`;
