import { useCallback, useState } from "react";
import { DefaultOptionType } from "@braze/beacon-lab-select";
import { useForm } from "react-hook-form";

export const useDeepLinkGenerator = () => {
  const [isAssetNameVisible, setIsAssetNameVisible] = useState(false);
  const [isUrlVisible, setIsUrlVisible] = useState(false);
  const [isDeepLinkVisible, setIsDeepLinkVisible] = useState(false);

  interface FormField {
    currentUrl: string;
    url: string;
    assetName: string;
    workspace: DefaultOptionType | null;
    assetType: DefaultOptionType | null;
    extraParams: string;
  }

  const { setValue, watch, reset } = useForm<FormField>({
    defaultValues: {
      currentUrl: "",
      url: "",
      assetName: "",
      workspace: null,
      assetType: null,
      extraParams: "",
    },
  });

  // Watch the form fields
  const currentUrl = watch("currentUrl");
  const url = watch("url");
  const assetName = watch("assetName");
  const workspace = watch("workspace");
  const assetType = watch("assetType");
  const extraParams = watch("extraParams");

  const onSetWorkspace = (workspaceOption: DefaultOptionType) => {
    setValue("workspace", workspaceOption);
  };

  const onSetCurrentUrl = (currentUrl: string) => {
    setValue("currentUrl", currentUrl);
  };

  const onSetUrl = (url: string) => {
    setValue("url", url);
  };

  const onSetAssetName = (assetName: string) => {
    setValue("assetName", assetName);
  };

  const onSetAssetType = useCallback(
    (assetTypeOption: DefaultOptionType) => {
      setValue("assetType", assetTypeOption);
      setIsAssetNameVisible(assetTypeOption?.value !== "page");
      setIsUrlVisible(assetTypeOption?.value === "page");
    },
    [setValue]
  );

  const onSetExtraParams = (extraParams: string) => {
    setValue("extraParams", extraParams);
  };

  const onReset = () => {
    reset({
      currentUrl: "",
      url: "",
      assetName: "",
      workspace: null,
      assetType: null,
      extraParams: "",
    });
    setIsAssetNameVisible(false);
    setIsUrlVisible(false);
    setIsDeepLinkVisible(false);
  };

  return {
    currentUrl,
    url,
    assetName,
    workspace,
    assetType,
    isAssetNameVisible,
    isUrlVisible,
    isDeepLinkVisible,
    extraParams,
    onSetWorkspace,
    onSetAssetType,
    onReset,
    onSetCurrentUrl,
    onSetUrl,
    onSetAssetName,
    setIsDeepLinkVisible,
    onSetExtraParams,
  };
};
