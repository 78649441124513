import pageNotFoundImg from "./404.svg";
import { PageNotFoundContainer, StyledLink } from "./styles";

const PageNotFound = () => (
  <PageNotFoundContainer>
    <img src={pageNotFoundImg} height={600} alt="404" />
    <StyledLink to="/">Return to Home </StyledLink>
  </PageNotFoundContainer>
);

export default PageNotFound;
