import { DefaultOptionType } from "@braze/beacon-lab-select";
import React from "react";
import { APIKeyEntry } from "./ApiKeyEntry/APIKeyEntry";
import { SDKEndpointSelect } from "./SDKEndpointSelect/SDKEndpointSelect";
import { Box, Heading } from "@braze/beacon-core";

type DashboardCompanyDetailsProps = {
  endpoint: DefaultOptionType | null;
  androidApiKey: string;
  iosApiKey: string;
  onAndroidApiKeyChanged: (androidApiKey: string) => void;
  oniOSApiKeyChanged: (androidApiKey: string) => void;
  onEndpointChanged: (endpoint: DefaultOptionType) => void;
};
export const DashboardCompanyDetails = ({
  endpoint,
  onEndpointChanged,
  androidApiKey,
  iosApiKey,
  onAndroidApiKeyChanged,
  oniOSApiKeyChanged,
}: DashboardCompanyDetailsProps) => (
  <Box>
    <Heading level={3}>Dashboard Company Details</Heading>
    <SDKEndpointSelect endpoint={endpoint} onEndpointChanged={onEndpointChanged} />
    <APIKeyEntry
      androidApiKey={androidApiKey}
      iOSApiKey={iosApiKey}
      onAndroidApiKeyChanged={onAndroidApiKeyChanged}
      oniOSApiKeyChanged={oniOSApiKeyChanged}
    />
  </Box>
);
