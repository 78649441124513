import { Security } from "@okta/okta-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Growth } from "./growth";
import { MainLayout } from "./main";
import { GrowthEngineering } from "./growth-engineering";
import { BISupport } from "./bi-support";
import { AnalyticsReporting } from "./analytics-reporting";
import { DataExtract } from "./data-extract";
import { CustomerData } from "./customer-data";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import SlidebotForm from "./slidebot";
import { ProductAnalytics } from "./product-analytics";
import { DataServices } from "./data-services";
import { ProductLedGrowth } from "./product-led-growth";
import { SandboxDashboard } from "./sandbox-dashboard";
import { TemplateValidator } from "./template-validator";
import { SandboxAdmin } from "./sandbox-admin";
import PageNotFound from "./page-not-found";
import { SlidebotFormEbr } from "./slidebot-ebr";
import { SlidebotPitchDeckForm } from "./slidebot-pitchdeck";
import { NewDataSource } from "./new-data-source";
import { DataEngineeringRequest } from "./data-engineering";
import { QRCodeGenerator } from "./qr-code-generator";
import { DeepLinkGenerator } from "./deeplink-generator";

const oktaClientId =
  (process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_OKTA_CLIENT_ID
    : window.REACT_APP_OKTA_CLIENT_ID) || "";

const oktaAuth = new OktaAuth({
  issuer: "https://braze.okta.com",
  clientId: oktaClientId,
  redirectUri: window.location.origin,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: false,
  // responseType: "code",
});
export const RootPage = () => {
  const history = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Growth />} />
            <Route path="/customer-data" element={<CustomerData />} />
            <Route path="/growth-engineering" element={<GrowthEngineering />} />
            <Route path="/ge/bi-support" element={<BISupport />} />
            <Route path="/analytics-reporting" element={<AnalyticsReporting />} />
            <Route path="/data-extract" element={<DataExtract />} />
            <Route path="/data-services" element={<DataServices />} />
            <Route path="/product-analytics" element={<ProductAnalytics />} />
            <Route path="/slidebot" element={<SlidebotForm />} />
            <Route path="/slidebot/ebr" element={<SlidebotFormEbr />} />
            <Route path="/slidebot/pitchdeck" element={<SlidebotPitchDeckForm />} />
            <Route path="/plg-to-gtm" element={<ProductLedGrowth />} />
            <Route path="/sandbox-dashboard" element={<SandboxDashboard />} />
            <Route path="/template-validator" element={<TemplateValidator />} />
            <Route path="/admin" element={<Growth />} />
            <Route path="/admin/sandbox" element={<SandboxAdmin />} />
            <Route path="/ge/new-data-source" element={<NewDataSource />} />
            <Route path="/ge/data-engineering" element={<DataEngineeringRequest />} />
            <Route path="/ge/qr-code-gen" element={<QRCodeGenerator />} />
            <Route path="/ge/deeplink-generator" element={<DeepLinkGenerator />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </MainLayout>
      </QueryParamProvider>
    </Security>
  );
};
