import { useEffect, useState } from "react";

import { Select } from "@braze/beacon-lab-select";

import { OpportunityResult } from "src/hooks/types";
import { useGetOpportunities } from "src/hooks/useGetOpportunities";
import { OptionsLabelValue } from "src/components/types";
import dayjs from "dayjs";
import { OpportunitySearchSelectProps } from "./types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const OpportunitySearchSelect = ({
  onSelectOpportunity,
  selectedAccount,
  includeStageName,
  isMulti = true,
  returnFullOption = false,
  noOpportunityOption = false,
}: OpportunitySearchSelectProps) => {
  const [opportunityOptions, setOpportunityOptions] = useState<OptionsLabelValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsLabelValue[]>([]);

  const { value, loading, refetch } = useGetOpportunities(!selectedAccount, selectedAccount?.ACCOUNT_ID || "");

  useEffect(() => {
    if (value) {
      const opportunities = includeStageName ? value.filter((o) => o.STAGE_NAME !== "Closed Lost") : value;
      let res = opportunities.map((opportunity: OpportunityResult) => ({
        label: opportunity.OPPORTUNITY_NAME,
        value: opportunity.OPPORTUNITY_ID,
        description: `Created on: ` + dayjs(opportunity.CREATED_DATE).format("LLL"),
      }));

      if (noOpportunityOption && res.length === 0) {
        res = [
          {
            label: "No Opportunity",
            value: "No Opportunity",
            description: "Please select only if an opportunity does not exist.",
          },
        ];
      }

      setOpportunityOptions(res);
    }
  }, [value, includeStageName, noOpportunityOption]);

  useEffect(() => {
    setSelectedOptions([]);
    onSelectOpportunity([]);
    if (selectedAccount?.ACCOUNT_ID === undefined) {
      setOpportunityOptions([]);
    } else {
      refetch({
        id: selectedAccount.ACCOUNT_ID,
      });
    }
  }, [selectedAccount?.ACCOUNT_ID]);

  return (
    <Select
      inputId="opportunity"
      isMulti={isMulti}
      isClearable
      isDisabled={!selectedAccount}
      isSearchable
      fluid
      options={opportunityOptions}
      menuPortalTarget={document.body}
      isLoading={loading}
      value={selectedOptions}
      placeholder="Type in to see results..."
      onChange={(selectedOptions) => {
        const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions].filter(Boolean);
        setSelectedOptions(optionsArray);

        // Determine what to return based on the returnFullOption prop
        const returnValue = returnFullOption
          ? optionsArray
          : optionsArray.map((selectedOption: OptionsLabelValue) => selectedOption.label);

        onSelectOpportunity(returnValue);
      }}
    />
  );
};
