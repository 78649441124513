import React from "react";
import { GenerateButton } from "src/components/QRCodeGenerator/GenerateSection/GenerateButton/GenerateButton";
import { ResetButton } from "src/components/QRCodeGenerator/GenerateSection/ResetButton/ResetButton";
import { StyledGenerateSection } from "./styles";

type GenerateSectionProps = {
  onGenerateButtonPressed: () => void;
  onResetButtonPressed: () => void;
  loading?: boolean;
};
export const GenerateSection = ({
  onGenerateButtonPressed,
  onResetButtonPressed,
  loading,
}: GenerateSectionProps) => (
  <StyledGenerateSection>
    <ResetButton onResetButtonPressed={onResetButtonPressed} />
    <GenerateButton onGenerateButtonPressed={onGenerateButtonPressed} loading={loading} />
  </StyledGenerateSection>
);
