import { API_BASE_URL } from "src/config";
import { useFetch } from "./useFetch";

type AppGroupResult = {
  APP_GROUP_ID: string;
  APP_GROUP_NAME: string;
};

export const useGetAppGroups = (appGroupFetchDisabled: boolean, accountId: string) =>
  useFetch<AppGroupResult[]>({
    url: `${API_BASE_URL}/api/company/appgroups`,
    lazyload: appGroupFetchDisabled,
    params: { id: accountId },
  });
