/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Checkbox, FieldLabel, Flex, Input, Label, TabContent, Textarea, makeToast } from "@braze/beacon-core";
import { Select } from "@braze/beacon-lab-select";

import { JiraIssueRequest, useCreateIssue } from "src/hooks/useCreateIssue";
import { ThankYou } from "src/pages/thank-you";
import { requiredLabel } from "src/components/required";
import { RequestType, RequestTypeOptions, WorkImpactOptions } from "src/pages/data-engineering/data";
import {
  ComingSoon,
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubmitButton,
  TopMargin,
} from "src/components/commonStyles";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import { useForm } from "src/hooks/useForm";
import { NavigateBackButton } from "src/components/NavigateBackButton/NavigateBackButton";
import { StringParam, useQueryParam } from "use-query-params";
import { getUserInformation } from "src/utils";
import { FormField, WorkImpact } from "src/pages/data-engineering/data";

export const DataEngineeringRequest = () => {
  const requestTitle = "Data Engineering Request";
  const userInfo = getUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(1);
  const [requestTypeParam, setRequestTypeParam] = useQueryParam("request_type", StringParam);

  const { formState, setFormFieldValue } = useForm<FormField>({
    requestType: requestTypeParam ? (requestTypeParam as RequestType) : RequestType.AccessPermissions,
    requestSummary: "",
    mainTitle: "",
    isConfirmed: false,
  });

  const { requestType, requestSummary, workImpact, mainTitle, isConfirmed } = formState;

  useEffect(() => {
    if (formState.requestType && formState.requestType !== requestTypeParam) {
      setRequestTypeParam(formState.requestType);
    }
  }, [requestTypeParam, setRequestTypeParam, formState.requestType]);

  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isConfirmedChecked = (event: any) => {
    setFormFieldValue("isConfirmed", event?.target?.checked);
  };

  const onSubmit = async () => {
    let issueData = `
      *Reporter*: ${userInfo?.email}
      *Request Type*: ${requestType}`;
    if (requestType !== RequestType.Bug) {
      issueData += `
        *Request Summary*: ${requestSummary}
        *Work Impact*: ${workImpact}
        `;
    }
    if (requestType === RequestType.Bug) {
      issueData += `
        *Bug Summary*: ${requestSummary}
        *Work Impact*: ${workImpact}`;
    }

    const jiraConfig = getJiraConfig(requestTitle, formState);
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", formState);
      return; // Required
    }

    const jiraPriorityId = (workImpact: string) => {
      switch (workImpact) {
        case WorkImpact.MyWork:
          return "3";
        case WorkImpact.MyTeam:
          return "2";
        case WorkImpact.MultipleTeams:
          return "1";
        default:
          return "4";
      }
    };

    const jiraIssueRequest: JiraIssueRequest = {
      fields: {
        project: {
          key: jiraConfig.jiraProjectKey,
        },
        description: issueData,
        summary: `${mainTitle}`,
        issuetype: {
          name: "Task",
        },
        labels: [],
        priority: {
          id: workImpact ? jiraPriorityId(workImpact) : "3",
        },
      },
    };

    if (requestType === RequestType.Bug) {
      jiraIssueRequest.fields.issuetype.name = "Bug"; //Feature --> Epic --> TBD
    } else {
      jiraIssueRequest.fields.issuetype.name = "Task";
    }
    // eslint-disable-next-line camelcase
    jiraIssueRequest.fields.customfield_10000 = jiraConfig.epicLink;
    jiraIssueRequest.fields.labels = jiraConfig.labels;
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };

  const submitDisabled =
    !mainTitle ||
    !requestType ||
    !isConfirmed === undefined ||
    !workImpact ||
    !requestSummary ||
    !isConfirmed ||
    createJiraIssueRes.loading;

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "12.5rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>{requestTitle}</FormHeading>
              <TabContent active={activeTab === 1}>
                <FormDetails>
                  <InputLabel label="Title" htmlFor="title" formatLabel={requiredLabel}>
                    <Input
                      id="title"
                      maxLength={200}
                      placeholder=""
                      value={mainTitle}
                      fullWidth
                      onChange={(e) => setFormFieldValue("mainTitle", e.target.value)}
                    />
                  </InputLabel>
                  <FieldLabel
                    label="What do you need help with?"
                    htmlFor="request-type"
                    formatLabel={requiredLabel}
                  >
                    <Select
                      inputId="request-type"
                      isClearable
                      isDisabled={false}
                      isSearchable
                      options={RequestTypeOptions}
                      menuPortalTarget={document.body}
                      fluid
                      onChange={(selectedOption) => {
                        if (
                          !selectedOption ||
                          selectedOption !== RequestTypeOptions.find((o: any) => o.value === requestType)
                        )
                          setFormFieldValue("requestType", selectedOption?.value);
                      }}
                      value={RequestTypeOptions.find((o: any) => o.value === requestType)}
                    />
                  </FieldLabel>
                  <Flex direction="column">
                    {requestType !== RequestType.Bug && (
                      <>
                        <InputLabel label="Request Summary" htmlFor="request-summary" formatLabel={requiredLabel}>
                          <Textarea
                            id="request-summary"
                            vSize="xl"
                            placeholder=""
                            maxLength={5000}
                            value={requestSummary}
                            fullWidth
                            onChange={(e) => setFormFieldValue("requestSummary", e.target.value)}
                          />
                        </InputLabel>
                      </>
                    )}
                    {requestType == RequestType.Bug && (
                      <>
                        <InputLabel label="Bug Summary" htmlFor="bug-summary" formatLabel={requiredLabel}>
                          <Textarea
                            id="bug-summary"
                            vSize="xl"
                            placeholder=""
                            maxLength={5000}
                            value={requestSummary}
                            fullWidth
                            onChange={(e) => setFormFieldValue("requestSummary", e.target.value)}
                          />
                        </InputLabel>
                      </>
                    )}
                    <InputLabel label="Work Impact" htmlFor="work-impact" formatLabel={requiredLabel}>
                      <Select
                        inputId="work-impact"
                        isClearable
                        isDisabled={false}
                        isSearchable
                        options={WorkImpactOptions}
                        fluid
                        onChange={(selectedOption) => {
                          setFormFieldValue("workImpact", selectedOption?.value);
                        }}
                        value={WorkImpactOptions.find((o) => o.value === workImpact)}
                      />
                    </InputLabel>
                    <Flex style={TopMargin}>
                      <Checkbox
                        checked={isConfirmed}
                        onChange={isConfirmedChecked}
                        id="confirmed"
                        indeterminate={false}
                        disabled={false}
                      >
                        <FieldLabel
                          formatLabel={requiredLabel}
                          style={{
                            lineHeight: 0.4,
                          }}
                          label="I confirmed that the help documents do not solve my problem."
                        />
                      </Checkbox>
                    </Flex>
                  </Flex>

                  <Flex direction="column">
                    {!!requestType && (
                      <SubmitButton variant="primary" intent="info" disabled={submitDisabled} onClick={onSubmit}>
                        Submit Request
                      </SubmitButton>
                    )}
                  </Flex>
                </FormDetails>
              </TabContent>
              <TabContent active={activeTab === 2}>
                <ComingSoon style={TopMargin}>
                  <Label>Coming Soon ...</Label>
                </ComingSoon>
              </TabContent>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou textVariation="GEGeneric" ticketKey={createJiraIssueRes.value.key} />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
